import React from 'react';
import styles from './OrderType.module.scss';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faDolly } from '@fortawesome/free-solid-svg-icons';
import { faCartFlatbedBoxes } from "@fortawesome/pro-solid-svg-icons";
import InventoryIcon from "@mui/icons-material/Inventory";
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import { useTranslation } from 'react-i18next';
import orderStyles from "../../pages/orders-module/orders-component/orders-component.module.scss";
import { OrderConfiguration, OrderEnum, OrdersTypeEnum } from '../../common/enums';
import { Tooltip } from '@mui/material';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';

export const mixedOrderTooltip = (mixOrderConfig) => {
  const orders = mixOrderConfig.split(",");
  let ordersTooltip = [];
  orders.forEach((order) => {
    ordersTooltip.push(OrdersTypeEnum[order])
  })
  return ordersTooltip.join(", ")
}

export const GridOrderType = ({ mixOrderConfig, t }) => {
  if (mixOrderConfig?.includes(",")) {
    return <Tooltip title={mixedOrderTooltip(mixOrderConfig)}><span>{t("OrdersTab.mixed")}</span></Tooltip>
  } else {
    switch (mixOrderConfig) {
      case OrderConfiguration.Delivery:
      case OrderEnum.Delivery:
        return <span className='textDimmerGray'>{t("OrdersTab.delivery")}</span>
      case OrderConfiguration.Service:
        return <span className='textDimmerGray'>{t("OrdersTab.service")}</span>
      case OrderConfiguration.Supplies:
        return <span className='textDimmerGray'>{t("Dashboard.supplies")}</span>
      case OrderConfiguration.PickUp:
      case OrderEnum.PickUp:
        return <span className='textDimmerGray'>{t("OrdersTab.pickup")}</span>
      case OrderConfiguration.PickUpAll:
        return <span className='textDimmerGray'>{t("OrdersTab.pickUpAll")}</span>
      default:
        return null;
    }
  }
}

const OrderType = ({ mixOrderConfiguration }) => {
  const { t } = useTranslation();

  const handleOrdersIcon = (mixOrderConfig) => {
    if (mixOrderConfig?.includes(",")) {
      return (
        <div>
          <Tooltip title={mixedOrderTooltip(mixOrderConfig)}>
            <SyncAltRoundedIcon className={`${styles.iconSize} ${styles.mixedOrderIcon} colorBlue`}
            />
            <span className={`${styles.mixedOrderValue} ${styles.deliveryStatusText}`}>{t("OrdersTab.mixed")}</span>
          </Tooltip>
        </div>
      )
    }
    else {
      switch (mixOrderConfig) {
        case OrderConfiguration.Delivery:
        case OrderEnum.Delivery:
          return (
            <div>
              <FontAwesomeIcon
                icon={faTruck}
                className={`${styles.iconSize} ${styles.marginLeftSix} colorBlue`}
              />
              <span className={styles.deliveryStatusText}> {t("OrdersTab.delivery")}</span>
            </div>
          )
        case OrderConfiguration.Service:
          return (
            <div className={styles.serviceIconLineHeight}>
              <ConstructionRoundedIcon
                className={`${styles.iconSize} colorBlue ${styles.marginLeftSix}`}
              />
              <span className={styles.deliveryStatusText}>{t("OrdersTab.service")}</span>
            </div>
          )
        case OrderConfiguration.Supplies:
          return (
            <>
              <InventoryIcon
                className={`${styles.iconSize} colorBlue`}
              />
              <span className={styles.deliveryStatusText}>{t('Dashboard.supplies')}</span>
            </>
          )
        case OrderConfiguration.PickUp:
        case OrderEnum.PickUp:
          return (
            <div>
              <FontAwesomeIcon
                icon={faDolly}
                className={`${orderStyles.backgroundColorGreen} ${styles.iconSize}`}
              />
              <span className={`${styles.deliveryStatusText} ${styles.pickUpWidth}`}>{t("OrdersTab.pickup")}</span>
            </div>
          );
        case OrderConfiguration.PickUpAll:
          return (
            <>
              <FontAwesomeIcon
                icon={faCartFlatbedBoxes}
                className={`${styles.iconSize} ${orderStyles.backgroundColorGreen}`}
              />
              <span className={`${styles.deliveryStatusText} ${styles.pickUpAllWidth}`}>{t("OrdersTab.pickUpAll")}</span>
            </>
          );
        default:
          return null;
      }
    }
  }

  return (
    <div className={styles.OrderType} data-testid="OrderType">
      <Stack direction="column" alignItems="center">
        {handleOrdersIcon(mixOrderConfiguration)}
      </Stack>
    </div>
  );
}

export default OrderType;
