import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { Button } from "@mui/base";
import EditIcon from "@mui/icons-material/Edit";
import styles from './editOrderButton.module.scss';
import { errorToast } from '../ToastMessage/ToastMessage';

const EditOrderButton = ({data, index}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const editOrder = async (item) => {
    if ( item?.status_description == "Incomplete" || item?.status_description == "Queued" ) {
      errorToast(t("toastMessage.editOrderErrorMsg"));
    }
    else {
        navigate(`/orders/edit-order/${item.order_id}`);
    }
  };

  return (
    <>
      <Tooltip title={ <span className={styles.tooltipFontSize} > Edit Order </span> } >
        <Button className={styles.blueButton} data-testid={"EditOrder" + index} onClick={(e) => {
          e.preventDefault();
          editOrder(data);
        }}
          variant="contained" size="small">
          <EditIcon className={styles.customSizeImage} />
        </Button>
      </Tooltip>
    </>
  )
}

export default EditOrderButton;