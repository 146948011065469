import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Chip from "@mui/material/Chip";
import { Accordion, AccordionDetails, AccordionSummary } from '../../../components/MuiCustomComponent/MuiCustomComponent';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputBase from "@mui/material/InputBase";
import styles from "./generic-accordion-card.module.scss";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { formatReportDate } from '../CommonFunctions/CommonFunctions';
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';

const GenericAccordionCard = ({ title, children, onReset, onSearch, searchSummary, setSearchSummary, onAccordionToggle, filterValues, setFilterValues, setOrderBranchId, setSelectedBillingDate, isChipDeleteClick, setChipDeleteClick, validBillingDate, setShowPatientState, selectedPriority, setSelectedPriority, selectedStatuses, setSelectedStatuses, setIsFormChipRemoved, statusDispose, setSelectedDispose, setSelectedProvider, setSelectedState, Status, setSelectedStatus, props, setPage = () => {}, setOffset = () => {}}) => {
  const { t } = useTranslation();
  localStorage.setItem("drawerSelectedItem", "Reports");

  const accordionRef = useRef(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const {addBranchOption, defaultChipValues} = props;
  
  const toggleGenericAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
    if (onAccordionToggle) {
      onAccordionToggle(!isAccordionOpen);
    }
  };
  const handleReset = () => {
    if (onReset) onReset();
    setIsAccordionOpen(false);
    if (onAccordionToggle) onAccordionToggle(false);
  };

  const handleSearch = (isSearching=false) => {

    if (isSearching) {
      setOffset(1);
      setPage(0);
    }

    if (onSearch) onSearch();
    setIsAccordionOpen(false);
    if (onAccordionToggle) onAccordionToggle(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordionRef.current && !accordionRef.current.contains(event.target) && !document.querySelector('div[role="dialog"]') && !document.querySelector('ul[role="listbox"]')) {
        setIsAccordionOpen(false);
        if (onAccordionToggle) onAccordionToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onAccordionToggle]);
  return (
    <div className={styles.GenericAccordionCard} data-testid="GenericAccordionCard">
      <Grid container className={styles.accordianBottomTop10px}>
        <Grid item container
          className={styles.accordianBottomTop10px}>
          <Grid item md={6} xs={12}>
            <div className={`${styles.searchContainer1Style} toast-container`} ref={accordionRef}>
              <Accordion
                data-testid="toggleGenericAccordion"
                expanded={isAccordionOpen}
                onChange={toggleGenericAccordion}
                className={styles.customGenericAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ cursor: "pointer" }} />}
                  aria-controls="panel-content"
                  id="panel-header"
                  className={styles.customGenericAccordionSummary}
                >
                  <div className="searchFilterIcon"  >
                    <FilterAltIcon sx={{ fontSize: "1.2rem" }} />
                    {searchSummary == "" &&
                      (<InputBase
                        placeholder={`Search ${title}`}
                        style={{ width: '250px'}}
                        className="filterIconInputBase"
                      />)
                    }
                  </div>
                  {searchSummary && (
                    <div style={{ marginTop: "8px" }} data-testid="recurringSummary">
                      {searchSummary.map((item, index) => {
                        return (
                          <Chip
                            key={item}
                            data-testid='chip-id'
                            label={<span style={{ whiteSpace: 'wrap' }}> <span style={{ whiteSpace: 'wrap' }}>
                              {item?.split(":").length === 1 ? "" : `${item?.split(":")[0]}: `} <strong>{item?.split(":")?.length === 1 ? item?.split(":")[0] : item?.split(":")[1]}</strong>
                            </span></span>}
                            className="paddingVertical_Five paddingHorizotal_Zero marginRightEight marginRightBottom height_fit_content"
                            onMouseDown={(e) => e.stopPropagation()}
                          />
                        );
                      })}
                    </div>
                  )}
                </AccordionSummary>
                <AccordionDetails className={styles.customGenericAccordionDetails}>
                  <div>{children}</div>
                  <Grid item container
                    className={styles.customResetButton}>
                    <Button
                      variant="contained"
                      className={`${styles.customButtonStyleReset} ${styles.buttonResponsive}`}
                      size="medium"
                      onClick={handleReset}
                      data-testid="handleResetButton" >
                      {t("PatientsListing.reset")}
                    </Button>
                    <Button
                      variant="contained"
                      className={validBillingDate ? `${styles.customButtonStyleSearch} ${styles.buttonResponsive} ${styles.DisabledButton}` : `${styles.customButtonStyleSearch} ${styles.buttonResponsive}`}
                      size="medium"
                      onClick={handleSearch}
                      disabled = {validBillingDate}
                      data-testid="handleSearchButton">
                      {t("PatientsListing.search")}
                    </Button>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default GenericAccordionCard;