import React, { useState } from "react";
import { Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AntSwitch } from "../MuiCustomComponent/MuiCustomComponent";
import styles from "./followButton.module.scss";
import { useTranslation } from "react-i18next";
import { handleFollowToggle } from "../../common/Utils/followToggle/handleFollowToggle";

const FollowButton = ({ toggleValue = false, toggleSetValue = () => { }, isCustomText = "", followPayload, isShowIcon = true }) => {

  const [isFollowing, setIsFollowing] = useState(!!toggleValue);
  const { t } = useTranslation();
  const toggleFollow = (e) => {
    e.stopPropagation();
    const temp = !isFollowing
    setIsFollowing(temp);
    toggleSetValue(e.target.checked);
    if(followPayload) {
      handleFollowToggle({...followPayload, status:temp === true ? 1 : 0})
    }

  };
  return (
    <div className={styles.FollowButton} data-testid="FollowButton">
      <Box display="flex" alignItems="center">
        <AntSwitch
          data-testid="antSwitch"
          checked={toggleValue}
          onChange={toggleFollow}
          inputProps={{ "aria-label": "follow/following" }}
        />
        {isShowIcon && <NotificationsIcon className={styles.myCustomClass1} />}
        {isCustomText == "" ? <span className={styles.followSize}>
          {isFollowing ? t('Common.following') : t('Common.follow')}
        </span> : <span className={styles.followSize}>
          {isCustomText}
        </span>}
      </Box>
    </div>
  )
}

FollowButton.propTypes = {};

FollowButton.defaultProps = {};

export default FollowButton;
