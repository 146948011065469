import { useTranslation } from 'react-i18next';
import ReportStyle from '../reports.module.scss';
import { v4 as uuidv4 } from 'uuid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GenericAccordionCard from '../../../common/Utils/generic-accordion-card/generic-accordion-card';
import ExportReportBtn from '../generic-filter-reports-component/ExportReportBtn/ExportReportBtn';
import NoData from '../../../components/noData/noData';
import loader from "../../../../src/assets/loader.gif";
import CustomGrid from '../../../components/CustomGrid';
import CustomTablePagination, { CustomPagination } from '../../../components/CustomTablePagination/CustomTablePagination';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import prescriptionReportStyle from './prescription-report.module.scss';
import { PatientsApi, PrescriptionApi, ReportsApi, Support } from '../../../constants/ApiConstants';
import { callApi, HTTPMethod, callApi as reportsRequest } from '../../../services/HttpService/HttpService';
import { Autocomplete, Box, Container, CssBaseline, styled, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { CompanyName } from '../generic-filter-reports-component/company-name-filter/CompanyName';
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FollowButton from '../../../components/followButton/followButton';
import withTitle from '../../../components/withTitle/withTitle';
import dayjs from 'dayjs';
import { DeleteDocumentDialog } from '../../prescription-module/prescription-listing/prescription-listing';
import AddPrescription from '../../prescription-module/add-prescription/add-prescription';
import { formatPrescriptionDate, reportFormattedDate } from '../../../common/Utils/CommonFunctions/CommonFunctions';
import { successToast } from '../../../components/ToastMessage/ToastMessage';
import { common } from '../../../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import StatusColor from '../../../common/Utils/StatusColor/StatusColor';

const PrescriptionReport = () => {
    const { t } = useTranslation();
    localStorage.setItem("drawerSelectedItem", "Reports");

    const autocompleteRef = useRef();
    const [prescriptionList, setPrescriptionList] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        loading: false,
        totalRecords: 10
    });
    const [addBranchOption, setAddBranchOption] = useState([]);
    const [statusData, setStatusData] = useState({
        list: [],
        selectedValue: null
    });
    const currentDate = dayjs();
    const orderedFromDefault = currentDate.subtract(3, 'year');
    const orderedToDefault = currentDate;
    const [filterValues, setFilterValues] = useState({
        company: '',
        selectedLocation: 'All',
        selectedPatient: '',
        fromDatePicker: orderedFromDefault,
        toDatePicker: orderedToDefault,
        statusData: 'All',
        isInactivePatientFollowing : '',
        isPickedUpEquipmentFollowing : ''
    });
    const [action, setAction] = useState({
        upload: false,
        orderId: '',
        prescriptionModuleEnabled: false,
        prescriptionSetting: [],
        download: false,
        delete: false,
        deletedFileName: '',
        fileDeleted: null,
    })
    const [searchSummary, setSearchSummary] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState('');
    const [locationId, setLocationId] = useState([]);
    const [optionsLocation, setOptionsLocation] = useState([]);
    const [companyDataName, setCompanyDataName] = useState(null);
    const [companyOption, setCompanyOption] = useState(null);
    const [optionsPatientName, setOptionsPatientName] = useState([]);
    const [validationError, setValidationError] = useState("");
    const [isPickedUpEquipmentFollowing, setIsPickedUpEquipmentFollowing] = useState(false);
    const [isInactivePatientFollowing, setIsInactivePatientFollowing] = useState(false);
    const [branchSelectedForPatient, setBranchSelectedForPatient] = useState(false);
    const [isChipDeleteClick, setChipDeleteClick] = useState(false);
    const [isExportLoading, setExportLoading] = useState(false);

    let monthDate = "";
    
    const handleChangePatientsFilter = (e, fieldName, filterValue) => {
        setFilterValues((prevValues) => ({
            ...prevValues,
            [fieldName]: (filterValue || e.target.value),
        }));
    };
    const defaultChipValues = ["Branch: All", "Status: All", `Ordered From: ${reportFormattedDate(orderedFromDefault)}`, `Ordered To: ${reportFormattedDate(orderedToDefault)}`]
    const props = { addBranchOption, setAddBranchOption, handleChangePatientsFilter, defaultChipValues }
    const { page, rowsPerPage, setPage, setOffset, setRowsPerPage, handleChangePage, handleChangeRowsPerPage } = CustomPagination();
    const paginatedData = prescriptionList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: { textAlign: 'center' },
      }));
      
    const prescriptionColumns = [
        {
            field: "patient_name",
            headerName: `${LineOfBusinessEnum.patientsToMembers} Name`,
            width: 150,
            renderCell: (params) => (
                <RouterLink
                    to={`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${params.row.patient_id}`}
                    onClick={(e) => { e.stopPropagation() }}
                    className={prescriptionReportStyle.columnLink}
                    data-testid='patientNameColumn'
                >
                    <span>
                        <b>{params.row.patient_name ? params.row.patient_name : "-"}</b>
                    </span>
                </RouterLink>
            ),
        },
        {
            field: "provider_name",
            headerName: t("PatientsListing.dmeProvider"),
            width: 150,
            renderCell: (params) => (params.row.provider_name ? params.row.provider_name : "-"),
        },
        {
            field: "initial_order_id",
            headerName: t("reports.initialOrder"),
            width: 120,
            renderCell: (params) => (
                <RouterLink to={params.row?.order_type == "Supplies" ? "/orders/supply-order-details/" + params.row.initial_order_id : "/orders/order-details/" + params.row.initial_order_id}
                    onClick={(e) => { e.stopPropagation() }}
                    className={prescriptionReportStyle.columnLink}>
                    <span>
                        {params.row.initial_order_id ? params.row.initial_order_id : "-"}
                    </span>
                </RouterLink>
            )
        },
        {
            field: "order_date",
            headerName: t("reports.orderDate"),
            width: 150,
            renderCell: (params) => (params.row.order_date ? reportFormattedDate(params.row.order_date) : "-"),
        },
        {
            field: "ordered_by",
            headerName: t("reports.orderedBy"),
            width: 150,
            renderCell: (params) => (params.row.ordered_by ? params.row.ordered_by : "-"),
        },
        {
            field: "expiration_date",
            headerName: t("reports.expirationDate"),
            width: 150,
            renderCell: (params) => (params.row.expiration_date ? reportFormattedDate(params.row.expiration_date) : "-"),
        },
        {
            field: "status",
            headerName: t("reports.status"),
            width: 200,
            renderCell: (params) => {
                const colorStyle = { color: StatusColor(params.row?.status) };
                return (
                    <div>
                        <span style={colorStyle}>
                            {params.row?.status === 'Noncompliant' ? (
                                <>
                                    {params.row.status}
                                    <HtmlTooltip title={(params.row?.file_id ? t("reports.statusTooltipWithFile") : t("reports.statusTooltipWithoutFile")).replace('#patient', LineOfBusinessEnum?.patientToMember.toLowerCase())}>
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                                style={{ marginLeft: '8px', color: 'var(--color-base)' }}
                                            />
                                        </span>
                                    </HtmlTooltip>
                                </>
                            ) : params.row.status ? (
                                params.row.status
                            ) : (
                                "-"
                            )}
                        </span>
                        {params.row?.rejection_reason && (
                            <p className={prescriptionReportStyle.rejectionContainer}>
                                {params.row.rejection_reason}
                            </p>
                        )}
                    </div>
                );
            }
        },  
        {
            field: "action",
            headerName: t("reports.action"),
            width: 180,
            renderCell: (params) => {
                const { status, file_id, initial_order_id, display_name } = params.row || {};
                const handleClick = (e, action) => {
                    e.stopPropagation();
                    e.preventDefault();
        
                    switch (action) {
                        case 'upload':
                            handleFilePermission(initial_order_id);
                            break;
                        case 'download':
                            handlePdfApi(file_id);
                            break;
                        case 'delete':
                            setAction({
                                ...action,
                                fileDeleted: params.row,
                                delete: true,
                                deletedFileName: display_name,
                            });
                            break;
                        default:
                            break;
                    }
                };
                const renderButton = (action, label, marginLeft = '0px') => {
                    const buttonStyle = action === 'delete' ? { color: 'var(--color-red)', cursor: 'pointer', marginLeft: '6px', textDecoration: 'underline' } : { marginLeft };
        
                    return (
                        <span onClick={(e) => handleClick(e, action)} style={buttonStyle}>
                            {label}
                        </span>
                    );
                };
        
                const isRejected = params?.row?.status === 'Rejected';
                const hasFileId = Boolean(params?.row?.file_id);
        
                return (
                    <div className={`${prescriptionReportStyle.columnLink} ${prescriptionReportStyle.actionLink}`}>
                        {isRejected && hasFileId ? (
                            <>
                                {renderButton('upload', t("reports.upload"))}
                                {renderButton('download', t("reports.download"), '6px')}
                                {renderButton('delete', t("reports.delete"), '6px')}
                            </>
                        ) : hasFileId ? (
                            <>
                                {renderButton('download', t("reports.download"))}
                                {renderButton('delete', t("reports.delete"), '6px')}
                            </>
                        ) : (
                            renderButton('upload', t("reports.upload"))
                        )}
                    </div>
                );
            },
        },  
    ];
    const getRecentOrderRowId = (row) => `${row?.patient_id}${uuidv4()}`;


    const handlePdfApi = async (fileId) => {
        if (fileId) {
            const pdfResponse = await callApi(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", fileId));
            if (pdfResponse && pdfResponse.status_code == 200) {
                const pdfUrl = pdfResponse.data.url;
                fetch(pdfUrl).then((response) => response.blob()).then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = pdfResponse.data.file_name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
                    .catch((error) => {
                        console.error('Error downloading file:', error);
                    });
            }
        }
    }

    const handleFilePermission = async (orderId) => {
        const response = await callApi(HTTPMethod.Get, PrescriptionApi.getFileUploadPermission + "?component=" + 2 + "&component_id=" + orderId)
        if (response && response.status_code == 200 && response.data) {
            setAction({
                ...action,
                orderId: orderId,
                upload: true,
                prescriptionModuleEnabled: response.data?.file_upload_access_model?.o2_prescriptions_module_enabled,
                prescriptionSetting: response.data?.file_upload_access_model
            });
        }
    }

    const handleFileDelete = async (e) => {
        e.preventDefault();
        setAction({ ...action, delete: false, fileDeleted: null, deletedFileName: '' })
        await callApi(HTTPMethod.Post, PrescriptionApi.removeFile.replace("{file_upload_id}", action.fileDeleted?.file_id));
        handleSearchPrescriptionReport(true);
        successToast(t("Prescriptions.documentRemovedSuccessfully"));
    }

    const handleStatusFilterApi = async () => {
     const response = await reportsRequest(HTTPMethod.Get, ReportsApi.reportStatus);
       if (response && response.status_code === 200 && response.data) {
          let statusList = [{
            rx_status: "All",
            rx_status_type_id: 0
            }];
            statusList.push(...response.data.status_type);
            setStatusData({ list: statusList, selectedValue: statusList[0] })
            }
        }
        
    const handleStatusFilterChange = (event, value) => {
        if(value){
            setStatusData({ ...statusData, selectedValue: value });
        } else{
            setStatusData({...statusData, selectedValue: { rx_status: "All", rx_status_type_id: 0 }});
        }
    }

    useEffect(() => {
        const fetchCompanyData = async () => {
            const response = await reportsRequest(HTTPMethod.Get, Support.hospices);
            if (response && response.status_code === 200 && response.data) {
                setCompanyOption(response?.data?.providers);
                setCompanyDataName(response?.data?.providers?.[0] || null);
            }
        };
        fetchCompanyData();
        handleStatusFilterApi();
    }, []);

    useEffect(() => {
        if (companyDataName) {
            handleSearchFilterHospiceBranch();
            handleSearchPrescriptionReport();
        }
    }, [companyDataName, page, rowsPerPage]);

    useEffect(() => {
        if (isChipDeleteClick) {
            handleSearchPrescriptionReport();
            setOffset(1);
            setPage(0);
            setChipDeleteClick(false);
        }
    }, [isChipDeleteClick]);

    useEffect(() => {
        setPagination({ ...pagination, totalRecords: prescriptionList?.length });
    }, [prescriptionList]);

    const handlePatientFilterOption = (option, state) => {
        if (option.length > 0 && state.inputValue && state.inputValue?.trim().length >= 3) {
          return option.filter((item) =>
            String(item.patient_full_name)
              .toLowerCase()
              .includes(state.inputValue.toLowerCase().trimStart().trimEnd())
          );
        }
        return option;
      };
    const handleAddBranchInputChange = (event, value) => {
        setSelectedLocation(null);
        setFilterValues((prevState) => ({
            ...prevState,
            selectedLocation: value,
          }));
     };
    const handleSearchFilterHospiceBranch = async () => {
        const requestBody = companyDataName.company_id
        const response = await reportsRequest(
            HTTPMethod.Get,
            ReportsApi.prescriptionReportBranch.replace( "{hospiceId}", requestBody ) );
        if (response && response.status_code == 200 && response.data) {
            let locations = [{
                name: "All",
                location_id: 0
              }];
            locations.push(...response.data.prescription_branches);
            setOptionsLocation(locations);
        } else {
            setOptionsLocation([]);
        }
    };

    const handleBranchFilterOption = (option, state) => {
        if (option?.length > 0 && state.inputValue?.trim().length >= 3 && filterValues.selectedLocation != 'All') {
            return option.filter((item) =>
                String(item.name)
                    .toLowerCase()
                    .includes(state.inputValue?.trim().toLowerCase())
            );
        }
        return option;
    };

    const isBranchValueMatchesOption = (option, value) => {
        return (option?.location_id == value?.location_id);
    };

    const onHandleLocationChange = (value) => {
        if(value){
            setSelectedLocation(value);
        }else{
            setSelectedLocation({location_id : 0, name: 'All'});
        }
        if(selectedPatient) {
             handleSearchPatientsName(selectedPatient, value?.location_id)   
            }
    };
    const handleSearchPatientsName = async (
        patientName = "",
        locationId = ""
    ) => {
        const response = await reportsRequest(
            HTTPMethod.Get,
            `${PatientsApi.patientsListingName}?PatientName=${patientName}&LocationId=${locationId}`
        );
        if (response && response.status_code == 200 && response.data) {
            const data = response.data.patient_names;
            setOptionsPatientName(data);
        } else {
            setOptionsPatientName([]);
        }
    };

    const handleChangeOrdersPatientsFilter = (e, fieldName) => {
        const value = (fieldName === "orderId")
        ? e.target.value.replace(regExPattern.numberOnly, '')
        : e.target.value;
        setFilterValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
        }));
      };
      const onHandlePatientChange = (event, value) => {
        if (value && value.patient_full_name) {
          setSelectedPatient(value.patient_full_name);
        }
        if (event && event.cancelable && value == null) {
          setSelectedPatient("");
        }
      };
      const handleOrderFromDateChange = (date) => {
        monthDate = "";
        if (filterValues.toDatePicker && date > filterValues.toDatePicker) {
          setValidationError(t("reports.pleaseSelectAValidDateRangeFromDateMustBeLessThanOrEqualToTheToDate"));
        } else {
          setValidationError("");
        }
        setFilterValues({ ...filterValues, fromDatePicker: date });
      };
    
      const handleOrderToDateChange = (date) => {
        if (filterValues.fromDatePicker && date < filterValues.fromDatePicker) {
          setValidationError(t("reports.pleaseSelectAValidDateRangeFromDateMustBeLessThanOrEqualToTheToDate"));
        } else {
          setValidationError("");
        }
        setFilterValues({ ...filterValues, toDatePicker: date });
      };

    const onReset = () => {
        setFilterValues({
            company : '',
            selectedLocation: 'All',
            selectedPatient: '',
            fromDatePicker: orderedFromDefault,
            toDatePicker: orderedToDefault,
            statusData : 'All' 
        });
        setSearchSummary("");
        setSelectedLocation(null);
        setSelectedPatient('');
        setLocationId([]);
        setIsPickedUpEquipmentFollowing(false);
        setIsInactivePatientFollowing(false);
        setStatusData({...statusData, selectedValue : null});
        setBranchSelectedForPatient(false);
        handleStatusFilterApi();
        setRowsPerPage(common.defaultRowPerPage);
        setPage(0);
        setOffset(1);
        handleSearchPrescriptionReport(true);
    }

     const handleSearchPrescriptionReport = async (isReset = false) => {
        const patientSearchLabel = LineOfBusinessEnum.patientToMember 
        const patientName = props?.selectedPatient?.trim() || selectedPatient?.trim()
        setPrescriptionList([]);
        setLoading(true);
        setPagination({ ...pagination, loading: true });
        const summaryParts = [];
        if (isReset) {
            summaryParts.push(
                `Branch: All`,
                `Status: All`,
                `Ordered From: ${reportFormattedDate(orderedFromDefault)}`,
                `Ordered To: ${reportFormattedDate(orderedToDefault)}`
            );
            fetchPrescriptionReportData(true);
        }
        else {
            if (filterValues.company) {
                summaryParts.push(`Company: ${filterValues.company}`);
            }
            if (selectedLocation) {
                summaryParts.push(`Branch: ${selectedLocation.name}`);
            }
              else{
                summaryParts.push("Branch: All")
              }
            if (patientName) {
                summaryParts.push(`${patientSearchLabel}: ${patientName}`);
            }
            if (filterValues.fromDatePicker && !monthDate) {
                summaryParts.push(`Ordered From: ${reportFormattedDate(filterValues.fromDatePicker)}`);
            }
            if (filterValues.toDatePicker) {
                summaryParts.push(`Ordered To: ${reportFormattedDate(filterValues.toDatePicker)}`);
            }
            if (statusData?.selectedValue?.rx_status) {
                summaryParts.push(`Status: ${statusData?.selectedValue?.rx_status}`);
            }
            if (isInactivePatientFollowing) {
                summaryParts.push(`Hide inactive ${LineOfBusinessEnum.patientsToMembers}`);
              } 
             if (isPickedUpEquipmentFollowing) {
                summaryParts.push(`Hide picked up equipment`);
              } 
           fetchPrescriptionReportData();
        }
        setSearchSummary(summaryParts);
    };

      const fetchPrescriptionReportData = async(isReset = false) => {  
        setAction((previousAction) => ({ ...previousAction, upload: false }));
        let requestBody = {
            branch_id: locationId?.[0] || 0,
            hospice_id: companyDataName?.company_id || 0,
            patient_id : optionsPatientName[0]?.patient_id || 0,
            ordered_from :  formatPrescriptionDate(filterValues.fromDatePicker ? filterValues.fromDatePicker : orderedFromDefault),
            ordered_to: formatPrescriptionDate(filterValues.toDatePicker ? filterValues.toDatePicker : orderedToDefault),
            status : statusData?.selectedValue?.rx_status_type_id || 0,
            hide_inactive_patients: isInactivePatientFollowing,
            has_o2_equipment: isPickedUpEquipmentFollowing
        };
        if(isReset) {
          requestBody = {
            branch_id:  0 ,
            hospice_id: companyDataName?.company_id || 0,
            patient_id: 0,
            ordered_from :  formatPrescriptionDate(orderedFromDefault),
            ordered_to: formatPrescriptionDate(orderedToDefault),
            status: 0,
            hide_inactive_patients: isInactivePatientFollowing,
            has_o2_equipment: isPickedUpEquipmentFollowing
          };    
        }
    
        const response = await reportsRequest(
          HTTPMethod.Post,
          ReportsApi.prescriptionReportData,
          requestBody
        );
        if (response && response.status_code === 200 && response.data) {
            setPrescriptionList(response?.data?.report);
        }
        setLoading(false);
        setPagination({ ...pagination, loading: false });
    }

    return <div className={ReportStyle.reportItemContainer}>
        <div className={ReportStyle.mainHeadingText}>
            {t("DrawerMenu.prescriptionReport")}
        </div>
        <div className={ReportStyle.filterContainer}>
            <div className={ReportStyle.filter}>
                <GenericAccordionCard
                    title={t("DrawerMenu.prescriptionReport")}
                    onReset={onReset}
                    onSearch={handleSearchPrescriptionReport}
                    searchSummary={searchSummary}
                    setSearchSummary={setSearchSummary}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    isChipDeleteClick={isChipDeleteClick}
                    setChipDeleteClick={setChipDeleteClick}
                    props={props}>
                    {companyOption?.length > 1 &&
                        <div className='marginBottomTwenty'>
                            <CompanyName companyOption={companyOption} companyDataName={companyDataName} setCompanyDataName={setCompanyDataName} handleChangePatientsFilter={handleChangePatientsFilter} />
                        </div>
                    }
                    <div className='marginBottomTwenty'>
                        <Autocomplete
                            popupIcon={<KeyboardArrowDownIcon />}
                            id="selectedLocation"
                            data-testid="selectedLocation"
                            value={selectedLocation}
                            inputValue={filterValues.selectedLocation}
                            onInputChange={handleAddBranchInputChange}
                            options={optionsLocation?.length > 0 ? optionsLocation : []}
                            getOptionLabel={(option) => option?.name || ''}
                            filterOptions={handleBranchFilterOption}
                            isOptionEqualToValue={isBranchValueMatchesOption}
                            onChange={(_, newValue, reason) => {
                                onHandleLocationChange(newValue);
                                if(reason === 'clear'){
                                    setSelectedPatient("");
                                    setOptionsPatientName([]);
                                }
                                const selectedBranch = optionsLocation.find(
                                    (option) => option?.name === newValue?.name);
                                if (selectedBranch) {
                                    setLocationId([selectedBranch.location_id]);
                                    setBranchSelectedForPatient(true);
                                } else {
                                    setBranchSelectedForPatient(false);
                                    setLocationId([]);
                                }
                            }}
                            clearOnBlur={false}
                            renderInput={(params) => (
                                <TextField  {...params}
                                    label="Branch"
                                    name="selectedLocation"
                                    InputLabelProps={{ style: { fontSize: "var(--common-heading)" } }}
                                />
                            )}
                            size="small" />
                    </div>
                    <div className='marginBottomTwenty'>
                        <Autocomplete
                            id="selectedPatient"
                            data-testid="selectedPatient"
                            popupIcon={<KeyboardArrowDownIcon />}
                            freeSolo
                            options={optionsPatientName?.length > 0 ? optionsPatientName : []}
                            ref={autocompleteRef}
                            inputValue={selectedPatient}
                            getOptionLabel={(option) => option?.patient_full_name ?? option}
                            onChange={(event, newValue) => {
                                onHandlePatientChange(event, newValue);
                                if (newValue == null) {
                                    setSelectedPatient("");
                                    setOptionsPatientName([]);
                                } else {
                                    setSelectedPatient(newValue?.patient_full_name ?? '');
                                }
                            }}
                            filterOptions={handlePatientFilterOption}
                            onInputChange={(event, newInputValue) => {
                                setSelectedPatient(newInputValue);
                                if (newInputValue && newInputValue?.trim().length >= 3) {
                                    handleSearchPatientsName(newInputValue.trim(), locationId[0]);
                                } else  {
                                    setOptionsPatientName([]);
                                }

                                if (event) {
                                    handleChangeOrdersPatientsFilter(event, "selectedPatient");
                                }
                            }}
                            disabled={!branchSelectedForPatient}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={branchSelectedForPatient ? `All ${LineOfBusinessEnum.patientsToMembers.toLowerCase()}` : `Select a branch to load ${LineOfBusinessEnum.patientsToMembers.toLowerCase()}`}
                                    name="selectedPatient"
                                    value={filterValues.selectedPatient}
                                    InputLabelProps={{
                                        style: { fontSize: "var(--common-heading)" },
                                    }}
                                />
                            )}
                            size="small"
                        />
                    </div>
                    <div className='marginBottomTwenty'>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Ordered From"
                                    value={filterValues.fromDatePicker}
                                    onChange={handleOrderFromDateChange}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: "50%" }}
                                />
                            </LocalizationProvider>
                            <div style={{ marginRight: '10px' }}></div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Ordered To"
                                    value={filterValues.toDatePicker}
                                    onChange={handleOrderToDateChange}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: "50%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        {validationError && (
                            <div style={{ color: "red" }}>{validationError}</div>
                        )}
                    </div>
                    <div className='marginBottomTwenty' data-testid="status-filter">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                id="statusFilter"
                                data-testid="statusFilter"
                                popupIcon={<KeyboardArrowDownIcon />}
                                options={statusData.list?.length > 0 ? statusData.list : []}
                                getOptionLabel={(option) => option?.rx_status}
                                noOptionsText={t("Dashboard.noDataFound")}
                                isOptionEqualToValue={(option, value) => option?.rx_status == value?.rx_status}
                                clearOnBlur={false}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("PatientsListing.status")}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { color: "0,0,0,.66" },
                                        }}
                                    />
                                )}
                                value={statusData.selectedValue}
                                size="small"
                                onChange={handleStatusFilterChange}
                            />
                        </Box>
                    </div>
                    <div>
                        <FollowButton
                            toggleValue={isPickedUpEquipmentFollowing}
                            toggleSetValue={setIsPickedUpEquipmentFollowing}
                            isCustomText={`Hide picked up equipment`}
                            isShowIcon={false}
                        />
                    </div>
                    <div className='marginBottomTwenty'>
                        <FollowButton
                            toggleValue={isInactivePatientFollowing}
                            toggleSetValue={setIsInactivePatientFollowing}
                            isCustomText={`Hide inactive ${LineOfBusinessEnum.patientsToMembers.toLowerCase()}`}
                            isShowIcon={false}
                        />
                    </div>
                </GenericAccordionCard>
            </div>
            <div><ExportReportBtn reportUrl={ReportsApi.prescriptionExport}
                reportRequsetBody = {{
                branch_id: locationId?.[0] || 0,
                hospice_id: companyDataName?.company_id || 0,
                patient_id : optionsPatientName[0]?.patient_id || 0,
                ordered_from :  formatPrescriptionDate(filterValues.fromDatePicker ? filterValues.fromDatePicker : orderedFromDefault),
                ordered_to: formatPrescriptionDate(filterValues.toDatePicker ? filterValues.toDatePicker : orderedToDefault),
                status : statusData?.selectedValue?.rx_status_type_id || 0,
                hide_inactive_patients: isInactivePatientFollowing,
                has_o2_equipment: isPickedUpEquipmentFollowing
                }}
                reportMethod={HTTPMethod.Post} filenamePrefix="Prescription" exportLoading={setExportLoading}/></div>
            </div>
        {isExportLoading && <NoData message={<img alt='Loading...' src={loader} />} />}

        <div data-testid="tableView" style={{ marginTop: "2.5rem" }}>
            {prescriptionList?.length > 0 ? <CustomGrid
                data={{
                    rows: paginatedData,
                    columns: prescriptionColumns,
                    getRowId: getRecentOrderRowId,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    showSlots: false,
                }}
            /> : <NoData
                message={
                    isLoading ? (
                        <img alt="Loading..." src={loader} />) : (
                        t("reports.noRecordsAvailable")
                    )
                }
            />}
            {prescriptionList?.length > 0 && (<div className={ReportStyle.pagination}>
                <CustomTablePagination
                    totalRecord={pagination.totalRecords}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    isLoading={pagination.loading}
                />
            </div>
            )}
        </div>
        <div>
            <AddPrescription
                isOpen={action.upload}
                addPrescriptionSettings={action.prescriptionSetting}
                prescriptionsModuleEnabled={action.prescriptionModuleEnabled}
                onClose={() => {
                    setAction({ ...action, upload: false });
                }}
                fetchPrescriptionReportData={handleSearchPrescriptionReport}
                prescriptionReportOrderid={action.orderId}
            />
        </div>
        <div>
            <DeleteDocumentDialog
                handleCancel={() => { setAction({ ...action, delete: false }) }}
                data-testid='DeleteDocumentDialog'
                handleSubmit={handleFileDelete}
                boldText={action.deletedFileName}
                isOpen={action.delete}
            />
        </div>
    </div>
}
export default withTitle(PrescriptionReport, "Prescription Report");
