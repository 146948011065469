
import React, {useState, useEffect } from 'react';
import { Typography, Button, Box, Table, TableHead, TableBody, TableContainer, TableRow, Paper, AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faDolly } from '@fortawesome/free-solid-svg-icons';
import styles from "./OrderConfirmationpageThird.module.scss";
import { styled } from '@mui/material/styles';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import { HTTPMethod, callApi as orderMultipleRequest } from "../../../services/HttpService/HttpService";
import { OrdersApi } from '../../../constants/ApiConstants';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { deliveryOrderType } from '../../../common/enums';
import { headerStyleForPickupEquipment, headerStyleForQuantity, cellStyle, cellStyleQuantity, StyledTableCell } from '../../../components/MuiCustomComponent/MuiCustomComponent';

const headerStyle = {
  fontSize: "16px",
  fontWeight: 600
}
const headerStyleForEquipment = {
  fontSize: "16px",
  fontWeight: 600,
  padding: '0px 16px 5px 16px'
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const OrderConfirmationDelivery = ({state}) => {
  const navigate = useNavigate();
  const [isMultipleAddress, setIsMultipleAddress] = useState(false);
  const afterFive = state?.priorityTimeFrame?.afterFive;
  const afterThree = state?.priorityTimeFrame?.afterThree;
  const isUrgent = state?.priorityTimeFrame?.isUrgent;
  const isRoutine = state?.priorityTimeFrame?.isRoutine;

  const parsedDeliveryConfirmationOrder = state?.deliveryOrder;
  const parsedConfirmationPickupData = state?.pickUpOrder;
  const serviceDeliveryOrder = state?.deliveryServiceData;
  const providerName = state?.provider?.providerName;
  const providerContact = state?.provider?.providerContact;
  const confirmationPatientId = state?.patientId;
  const doNotCallProvider = state?.doNotCallProvider;


  const filteredSupplyOrder = state.deliveryOrder
  .filter(order => order.is_supply_order)
  ?.map(order => ({
    order_id: order.order_id,
    provider: order.provider,
    order_items: order.order_items
  }));


  useEffect(() => {
    if (confirmationPatientId){
      addressLength()
    }
  }, [confirmationPatientId]);

  const addressLength = async () => {
    const response = await orderMultipleRequest(HTTPMethod.Get, OrdersApi.addressList.replace("{patient_id}", confirmationPatientId))
    if ( response && response.status_code == 200 && response.data?.patient_product_address_list_result?.length > 1) {
      let isMultiple = false;
      const addressList = response.data?.patient_product_address_list_result
      .filter((item,index,arr)=>arr.findIndex(val=>val.address_id == item.address_id) == index)
      if (addressList.length > 1 || parsedDeliveryConfirmationOrder[0]?.address_id !== addressList[0].address_id ){
        isMultiple = true
      }
      setIsMultipleAddress(isMultiple);
    }
  }

  if (parsedDeliveryConfirmationOrder && parsedDeliveryConfirmationOrder[0]?.recuring_order_id) {
    localStorage.setItem('recurringOrderFromConfirmation', true)
  }

  const handleNewOrder = (event) => {
    event.preventDefault();
    localStorage.setItem("selectedOrderType", deliveryOrderType.Delivery)
    navigate("/orders/new-order");
  };

  const handleRecurringOrder = (event) => {
    if (parsedDeliveryConfirmationOrder){
      navigate(`/recurring-orders/recurring-order-details/${parsedDeliveryConfirmationOrder[0]?.recuring_order_id}`)
    }
  }

  const handlePickupOrder = () => {
    const patientDetails = JSON.parse(sessionStorage.getItem("PatientDetails"))
    navigate(`/orders/new-order`, {state: { order_type: deliveryOrderType.PickUp, branchObject: {branchId: patientDetails?.hospice_branch_id, branchName: patientDetails?.hospice_branch, branchState: patientDetails?.branch_state}, 
     patientsEquipmentPickupObject:{patient_id: patientDetails?.patient_id, address_id: patientDetails?.address[0]?.address_id ,patient_full_name: `${patientDetails?.patient_last_name}, ${patientDetails?.patient_first_name} ${patientDetails?.patient_middle_initial}`,  }, }, 
  });
  };
  
  return (
    <div style={{ textAlign: 'center', marginTop: '50px', paddingRight: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Order submitted successfully!
      </Typography>

      {afterThree && (isRoutine || isUrgent) && !doNotCallProvider && 
        <Alert icon={false} severity={afterFive ? "warning" :"info"} className={styles.confirmationAlertStyling} >
          {afterThree && !afterFive &&<AlertTitle className='fontWeight600' sx={{ fontSize: "var(--secondary-heading)" }}>Delivery Timeframe Notice</AlertTitle>}
          {afterFive && <div className='displayFlex'>
            <Box pr={1}><WarningRoundedIcon sx={{ color: "var(--color-orange)" }} /></Box><AlertTitle className={styles.alertTitle} > After-Hours Order Notice </AlertTitle></div>}
          <span className='fontWeight600'>
          {afterThree && !afterFive && <p>This delivery is being placed after 3 pm. If you need this order processed tonight, you may need to contact your DME provider using the information below. Otherwise, it could be processed on the next business day.</p>}
          {afterFive && isRoutine && <p>This delivery is being placed after hours. If you need this order processed tonight, please contact your DME provider using the information below. Otherwise, it will be processed on the next business day.</p>}
            {afterFive && isUrgent && <p>  This delivery is being placed after hours. <span className={styles.textDecorationUnderline}>To ensure timely delivery</span>, you need to contact your DME provider using the information below.</p>}
          <div className={styles.lineHeight} >
          <p>{providerName}</p>
          <p>{providerContact}</p>
        </div>
          </span>
        </Alert>
      }
      {isMultipleAddress && (
        <Alert severity="warning" icon={false} className={styles.confirmationAlertStyling}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            This patient now has equipment ordered at multiple locations.
          </Typography>

          <Typography sx={{ fontSize:"var(--common-content)"}}>
            If the patient will not be returning to their previous address, please use the button below to pick up equipment no longer needed at the previous location. To ensure patient care is not affected, you may request a pickup order for a future date.
          </Typography>
        </Alert>
      )}


      {parsedDeliveryConfirmationOrder && parsedDeliveryConfirmationOrder[0]?.recuring_order_id &&
        (<Button
          className={styles.buttonResponsive}
          onClick={handleRecurringOrder}
          variant="contained"
          color="primary"
          size="large"

          startIcon={<RepeatRoundedIcon icon={faClipboardList} />}
        >
          View Recurring Order
        </Button>)
      }
      <Button
        className={styles.buttonResponsive}
        onClick={handleNewOrder}
        variant="contained"
        color="primary"
        size="large"

        startIcon={<FontAwesomeIcon icon={faClipboardList} />}
      >
        Place Another Order
      </Button>

      {isMultipleAddress && <Button
        className={styles.buttonResponsive1}
        onClick={handlePickupOrder}
        variant="contained"
        color="primary"
        size="large"
       data-testid='createPickup'
        startIcon={<FontAwesomeIcon icon={faDolly} />}
      >
        Create pickup for this patient
      </Button>}
        
      {parsedDeliveryConfirmationOrder && parsedDeliveryConfirmationOrder?.map((orderAllData, index) => (
        !orderAllData.is_supply_order && (<TableContainer component={Paper} sx={{ mt: 2 }} key={orderAllData?.order_id}>
          <Box className={styles.scopedClassName}>
            <span className={styles.scopedClassHeading}>
              Order #<RouterLink to={"/orders/order-details/" + orderAllData?.order_id} className={styles.orderIdLink}>
                <span>
                  <b>{orderAllData?.order_id}</b>
                </span>
              </RouterLink>
            </span>
          </Box>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ borderBottom: 'none' }}><span style={headerStyle}>Provider:</span> <span className={styles.scopedClassNameLine} >{orderAllData?.provider}</span></StyledTableCell>
              </TableRow>
              {(orderAllData.order_items.length == 1 && orderAllData.order_items[0].product_name?.toLowerCase() !== "service call" 
              && orderAllData.order_items[0].product_name?.toLowerCase() !== "service") 
              || (orderAllData.order_items.length > 1) && <TableRow>
                 <StyledTableCell style={headerStyleForEquipment}>Order Equipment</StyledTableCell>
                 <StyledTableCell style={headerStyleForQuantity}>Quantity</StyledTableCell>
              </TableRow>
              }

              {(orderAllData.order_items.length == 1 && orderAllData.order_items[0].product_name?.toLowerCase() !== "service call" 
              && orderAllData.order_items[0].product_name?.toLowerCase() !== "service")  && <TableRow>
                 <StyledTableCell  style={headerStyleForEquipment}>Order Equipment</StyledTableCell>
                 <StyledTableCell style={headerStyleForQuantity}>Quantity</StyledTableCell>
              </TableRow>
              }
            </TableHead>
            <TableBody>
              {orderAllData.order_items?.map((orderItem, index) => (
                orderItem.product_name?.toLowerCase() !== "service call" && orderItem.product_name?.toLowerCase() !== "service" && <StyledTableRow key={orderItem?.product_id}>
                  <StyledTableCell className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                    {orderItem?.product_name}
                  </StyledTableCell>
                  <StyledTableCell className={styles.scopedClassNameLine} style={cellStyleQuantity}>{orderItem?.quantity}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            {serviceDeliveryOrder && serviceDeliveryOrder.length > 0 && (<>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={headerStyleForEquipment}>Service Equipment</StyledTableCell>
                  <StyledTableCell style={headerStyleForQuantity}>Quantity</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceDeliveryOrder?.map((orderItem, index) => (
                  orderItem.isChecked && <StyledTableRow key={orderItem?.product_id}>
                    <StyledTableCell className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                      {orderItem?.equipment_name}
                    </StyledTableCell>
                    <StyledTableCell className={styles.scopedClassNameLine} style={cellStyleQuantity}>1</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody></>)
            }
          </Table>
        </TableContainer>)
      ))}
  
      {filteredSupplyOrder.length > 0 &&  <TableContainer component={Paper} sx={{ mt: 2 }}>
         <Box className={styles.scopedClassName}>
           <span className={styles.scopedClassHeading}>
             Supply Order #<RouterLink to={"/orders/supply-order-details/" + filteredSupplyOrder[0]?.order_id} className={styles.orderIdLink}>
               <span>
                 <b>{filteredSupplyOrder[0]?.order_id}</b>
               </span>
             </RouterLink>
           </span>
         </Box>
         <Table aria-label="customized table">
           <TableHead>
             <TableRow>
               <StyledTableCell sx={{ borderBottom: 'none' }}><span style={headerStyle}> Provider :</span> <span className={styles.scopedClassNameLine}>{filteredSupplyOrder[0]?.provider} </span></StyledTableCell>
             </TableRow>
            
             <TableRow>
                <StyledTableCell style={headerStyleForEquipment}>Supply items</StyledTableCell>
                <StyledTableCell style={headerStyleForQuantity}>Quantity</StyledTableCell>
             </TableRow>
             
           </TableHead>
           <TableBody>
             {filteredSupplyOrder[0]?.order_items && filteredSupplyOrder[0]?.order_items?.map((supplyItem) => (
              <StyledTableRow key={supplyItem.product_name}>
                 <StyledTableCell className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                   {supplyItem.product_name}
                 </StyledTableCell>
                 <StyledTableCell className={styles.scopedClassNameLine} style={cellStyleQuantity}>{supplyItem.quantity}</StyledTableCell>
               </StyledTableRow>
             ))}
           </TableBody>

         </Table>
       </TableContainer>
      }


      {parsedConfirmationPickupData &&
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Box className={styles.scopedClassName}>
            <span className={styles.scopedClassHeading}>
              Order #<RouterLink to={"/orders/order-details/" + parsedConfirmationPickupData?.success_full_orders} className={styles.orderIdLink}>
                <span>
                  <b>{parsedConfirmationPickupData?.success_full_orders}</b>
                </span>
              </RouterLink>
            </span>
          </Box>

          <Table aria-label="customized table">
            <TableHead>
              <TableRow >
              <StyledTableCell style={headerStyleForPickupEquipment}>Order Equipment </StyledTableCell>
              <StyledTableCell style={headerStyleForQuantity}>Quantity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedConfirmationPickupData && parsedConfirmationPickupData?.order_equipments?.map((item) => (
                <StyledTableRow key={item?.equipment_name}>
                  <StyledTableCell className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                    {item?.equipment_name}
                  </StyledTableCell>
                  <StyledTableCell className={styles.scopedClassNameLine} style={cellStyleQuantity}>1</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
      }
    </div>
  );
};

export default OrderConfirmationDelivery;
