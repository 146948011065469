import React, { useEffect, useState } from "react";
import styles from "./prescription-listing.module.scss";
import { Box, Container, Stack } from "@mui/system";
import { Button, Card, CardContent, Chip, CssBaseline, Grid, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useTranslation } from "react-i18next";
import { HTTPMethod, callApi as PrescriptionListingApiCall } from "../../../services/HttpService/HttpService";
import { PrescriptionApi } from "../../../constants/ApiConstants";
import { FileType, FileUploadComponent, StatusFileUpload } from "../../../common/enums";
import AddPrescription from "../add-prescription/add-prescription";
import NoData from "../../../components/noData/noData";
import loader from "../../../../src/assets/loader.gif";
import EditPrescription from "../edit-prescription/edit-prescription";
import CommonAlert from "../../../components/common-alert/common-alert";
import PrescriptionListingActions from "../prescription-listing-actions/prescription-listing-actions";
import { successToast } from "../../../components/ToastMessage/ToastMessage";

let StatusColors = [
  "#FF7300",
  "#777777",
  "#619633",
  "#D2322D"
];
export function statusColorForChip(approvalStatus) {
  switch (approvalStatus) {
    case "Pending Review":
      return StatusColors[0];
    case "Accepted":
      return StatusColors[2];
    case "Rejected":
      return StatusColors[3];
    case "Expired":
      return StatusColors[1];
    default:
      return StatusColors[1];
  }
}
function formatExpirationDate(dateTimeString) {
  const dateObject = new Date(dateTimeString);
  const formattedDate = dateObject.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
}
export const StatusName = ({item}) =>{
  return(
    <Box
    display="flex"
    flexDirection={{ xs: "column", sm: "row" }}
    justifyContent="space-between"
    className={styles.fieldAlignment}>
      <Typography
        className={styles.customTextWrap}
        sx={{ marginBottom: "5px" }}>
        <span className={styles.customTextWrap}>{item?.display_name}</span>
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        display="flex"
        marginBottom={"5px"}
        alignItems={{ sm: "flex-start" }}
        justifyContent={{
          xs: "flex-start",
          sm: "space-between",
        }}
      >
        {item?.status_name !== null && (
          <div className={styles.chipContainer}>
            <Chip
              label={StatusFileUpload[item?.status_name]}
              sx={{
                backgroundColor: statusColorForChip(
                  StatusFileUpload[item?.status_name]
                ),
              }}
              className={styles.customBadgeChip}
            />
          </div>
        )}
      </Stack>
   </Box>
  )
}
export const DeleteDocumentDialog = ({ isOpen, handleCancel, boldText, handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.CommonAlert} data-testid="CommonAlert">
      <Dialog open={isOpen} onClose={handleCancel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
      >
        <DialogTitle className={styles.commonDialogTitle}>
          <span className={styles.commonFexContainer}>{t("Prescriptions.removeDocument")}</span>
        </DialogTitle>
        <DialogContent style={{ padding: '15px 25px' }}>
          <p className={styles.dialogDescription}>
            <span className="displayFlex">{t("Prescriptions.deleteDocumentContent")}</span>
            {boldText && <span className={styles.dialogBold}>{boldText}</span>}?
          </p>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around", padding: '14px 16px' }}>
          <Button
            onClick={handleCancel}
            data-testid="cancel"
            className={styles.customCancelButton}
            variant="contained" size="small">
            {t("Prescriptions.cancel")}
          </Button>
          <Button
            data-testid='submitButton'
            onClick={handleSubmit}
            className={styles.customYesButton}
            variant="contained" size="small">
            {t("Prescriptions.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const DocumentListing = ({ fileSetting, componentId, component = 1 }) => {
  const { t } = useTranslation();
  const [documentListData, setDocumentListData] = useState([]);
  const [fileDeleted, setFileDeleted] = useState(null);
  const [deletedFileName, setDeletedFileName] = useState('');
  const [documentList, setDocumentList] = useState(null)

  useEffect(() => {
    if (componentId) {
      handleDocumentListing();
    }
  }, [componentId]);

  const handleDocumentListing = async () => {
    const requestBody = {
      order_id: component == FileUploadComponent.EditOrder ? componentId : null,
      patient_id: component == FileUploadComponent.Ordering ? componentId : null
    };
    const response = await PrescriptionListingApiCall(
      HTTPMethod.Post,
      PrescriptionApi.prescriptionListing,
      requestBody
    );
    if (response && response.status_code == 200 && response.data) {
      setDocumentListData(response?.data?.files);
    } else {
      setDocumentListData([]);
    }
  };

  const handlePdfApi = async (fileId, isViewPdf) => {
    if (fileId) {
      const pdfResponse = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", fileId));
      if (pdfResponse && pdfResponse.status_code == 200) {
        const pdfUrl = pdfResponse.data.url;
        if (isViewPdf) {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfUrl;
          link.click();
        } else {
          fetch(pdfUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement('a');
              a.href = url;
              a.download = pdfResponse.data.file_name;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch((error) => {
              console.error('Error downloading file:', error);
            });
        }

      }
    }
  }

  const handleEditFile = (item) => {
    setDocumentList(item);
  }

  const handleFileDelete = async (e) => {
    e.preventDefault();
    await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.removeFile.replace("{file_upload_id}", fileDeleted?.file_id));
    setFileDeleted(null);
    handleDocumentListing();
    successToast(t("Prescriptions.documentRemovedSuccessfully"));
  }

  const documentLength = documentListData?.length ?? 1;

  return (
    <>
      {documentLength > 0 &&
        <Grid item xs={12} mt={2}>
          {documentListData.map((item, index) => {
            return (
              (((fileSetting || (!fileSetting && !item.is_o2_prescription)) && (item?.status !== 4 || component !== FileUploadComponent.Order)) &&
                <Card variant="outlined"
                  key={item.file_id}
                  sx={{
                    textAlign: "left", borderRadius: `${index === 0 ? "4px 4px 0 0" : index === documentLength - 1
                      ? "0 0 4px 4px" : "0"}`, borderBottom: `${index === documentLength - 1 ? "1px solid rgba(0,0,0,0.12)" : "none"}`,
                  }}>
                  <CardContent style={{ position: "relative", paddingBottom: "16px" }}>
                    <StatusName item={item}/>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {item?.order_id && item?.is_o2_prescription && <Grid container item>
                          <Typography className={styles.scopedClassNameLine}>
                            <span className={styles.customText4}>{t('Dashboard.orderID')}:</span>
                            <span>
                              <Link
                                to={`/orders/order-details/${item?.order_id}`}
                                target="_blank"
                                className={styles.customLink} >
                                {item?.order_id}
                              </Link>
                            </span>
                          </Typography>
                        </Grid>}
                        {item?.is_o2_prescription && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.fileType')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {FileType[item?.is_o2_prescription]}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.formatted_expiration_date && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {t('Prescriptions.expiration')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {formatExpirationDate(item?.formatted_expiration_date)}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.rejection_reason && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.providerComment')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.rejection_reason}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.formatted_created_date && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine} >
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.uploadedOn')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.formatted_created_date}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.created_by_user && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.uploadedBy')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.created_by_user}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.formatted_last_updated_date && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.updatedOn')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.formatted_last_updated_date}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.last_updated_by_user && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.updatedBy')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.last_updated_by_user}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.formatted_rejected_on && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.rejectedOn')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.formatted_rejected_on}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                        {item?.rejected_by && (
                          <Grid container item>
                            <Typography
                              className={styles.scopedClassNameLine}>
                              <span className={styles.customText4}>
                                {" "}
                                {t('Prescriptions.rejectedBy')}:
                              </span>
                              <span className={styles.customTextBold1}>
                                {item?.rejected_by}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className={styles.buttonContainer}>
                        <Stack
                          direction="row"
                          className={styles.buttonStyling}>
                          <Tooltip
                            title={
                              <span className={styles.tooltipFontSize}>
                                {t("Prescriptions.viewDocument")}
                              </span>
                            } >
                            <Button
                              className={styles.greenButton}
                              variant="contained"
                              data-testid={"ViewDocument" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePdfApi(item?.file_id, true);
                              }}
                              size="small">
                              <RemoveRedEyeIcon
                                className={styles.customSizeImage} />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              <span className={styles.tooltipFontSize}>
                                {t("Prescriptions.editDocument")}
                              </span>
                            } >
                            <Button
                              className={styles.blueButton}
                              variant="contained"
                              size="small"
                              data-testid={"Edit" + index}
                              onClick={(e) => {
                                handleEditFile(item);
                              }}>
                              <EditIcon className={styles.customSizeImage} />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              <span className={styles.tooltipFontSize}>
                                {t("Prescriptions.downloadDocument")}
                              </span>
                            } >
                            <Button
                              className={styles.blueButton}
                              variant="contained"
                              data-testid={"DownloadDocument" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePdfApi(item?.file_id, false);
                              }}
                              size="small">
                              <SaveAltIcon
                                className={styles.customSizeImage} />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              <span className={styles.tooltipFontSize}>
                                {t("Prescriptions.removeDocument")}
                              </span>}>
                            <Button
                              className={styles.redButton}
                              variant="contained"
                              data-testid={"Delete" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                setDeletedFileName((item?.display_name ?? ""));
                                setFileDeleted(item);
                              }}
                              size="small">
                              <DeleteIcon className={styles.customSizeImage} />
                            </Button>
                          </Tooltip>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>)
            );
          })}
        </Grid>}
      <DeleteDocumentDialog
        handleCancel={() => { setFileDeleted(null); }}
        data-testid='DeleteDocumentDialog'
        handleSubmit={handleFileDelete}
        boldText={deletedFileName}
        isOpen={!!fileDeleted}
      />
      {documentList && <EditPrescription isEditDocumentOpen={!!documentList}
        currentListData={documentList} setIsEdited={handleDocumentListing} addPrescriptionSettings={fileSetting}
        handleClose={() => {
          setDocumentList(null);
        }} />}
    </>
  );
};
export const PatientDocumentListing = ({ fileSetting, componentId, component = 1 }) => {
  const { t } = useTranslation();
  const [documentListData, setDocumentListData] = useState([]);
  const [fileDeleted, setFileDeleted] = useState(null);
  const [deletedFileName, setDeletedFileName] = useState('');
  const [isEditDocumentOpen, setIsEditDocumentOpen] = useState(false)
  const [prescriptionFileId, setPrescriptionFileId] = useState('');
  const [isEdited, setIsEdited] = useState(false)
  useEffect(() => {
    if (componentId) {
      handleDocumentListing();
    }
  }, [componentId]);
  const handleDocumentListing = async () => { 
    const requestBody = {
      order_id: null,
      patient_id: componentId
    };
    const response = await PrescriptionListingApiCall(
      HTTPMethod.Post,
      PrescriptionApi.prescriptionListing,
      requestBody
    );
    if (response && response.status_code == 200 && response.data) {
      setDocumentListData(response?.data?.files);
    } else {
      setDocumentListData([]);
    }
  };
  const handlePdfView = async (item) => {
    const pdfAPIResponseView = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", item.file_id));
    if (pdfAPIResponseView && pdfAPIResponseView.status_code == 200) {
      const pdfUrl = pdfAPIResponseView.data.url;
      window.open(pdfUrl);
    }
  }
  const handleEditFile = (item) => {
    setPrescriptionFileId(item);
    setIsEditDocumentOpen(true);
  }

  const downloadFile = async (item) => {

    const pdfAPIResponseView = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", item.file_id));
    if (pdfAPIResponseView && pdfAPIResponseView.status_code == 200) {
      const downloadUrl = pdfAPIResponseView.data.url;

      fetch(downloadUrl)
        .then((response) => response.blob())
        .then((blob) => {

          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = pdfAPIResponseView.data.file_name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    }

  };

  const handleFileRemove = async (e, item) => {
    e.preventDefault();
    const response = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.removeFile.replace("{file_upload_id}", fileDeleted?.file_id));

    if (response && response.status_code == 200 && response.data) {
      handleDocumentListing();
      successToast(t("Prescriptions.documentRemovedSuccessfully"));
    }
    setFileDeleted(null);
  }

  return (
    <>
      <Grid xs={12} mt={2}>
        {documentListData?.length > 0 &&
          documentListData?.map((item, index) => (
            <DocumentListingData key={item?.file_id} item={item} index={index} fileTypeData={documentListData} t={t} handleEditFile={handleEditFile} handlePdfView={handlePdfView} downloadFile={downloadFile} handleFileRemove={handleFileRemove} setFileDeleted={setFileDeleted} setDeletedFileName={setDeletedFileName} />
          ))}
      </Grid>
      {isEditDocumentOpen && <EditPrescription isEditDocumentOpen={isEditDocumentOpen}
        currentListData={prescriptionFileId} setIsEdited={setIsEdited} addPrescriptionSettings={fileSetting}
        handleClose={() => {
          setIsEditDocumentOpen(false);
          handleDocumentListing();
        }}> </EditPrescription>}
      <CommonAlert contentText={deletedFileName}
        headerText="Remove Document"
        okText="Proceed"
        cancelText="Cancel"
        isOpen={!!fileDeleted}
        handleSubmit={handleFileRemove}
        okButtonStyle={'red'}
        handleCancel={() => { setFileDeleted(null) }}
      />
    </>

  )

}
export const DocumentListingData = ({ index, item, fileTypeData, t, handleEditFile, handlePdfView, downloadFile, prescriptionsModuleEnabled, filetypeOxygenData, buttonStates, setDeletedFileName, setFileDeleted }) => {
  return (<Card
    variant="outlined"
    key={index}
    sx={{
      textAlign: "left",
      borderRadius: `${index === 0
        ? "4px 4px 0 0"
        : index === fileTypeData?.length - 1
          ? "0 0 4px 4px"
          : "0"
        }`,
      borderBottom: `${index === fileTypeData?.length - 1
        ? "1px solid rgba(0,0,0,0.12)"
        : "none"
        }`,
    }}
  >
    <CardContent style={{ position: "relative", paddingBottom: "16px" }}>
      <StatusName item={item}/>
      <Grid container>
        <Grid item xs={12} lg={6}>
          {prescriptionsModuleEnabled && filetypeOxygenData?.length > 0 && buttonStates && item.order_id && <Grid container item>
            <Typography className={styles.scopedClassNameLine}>
              <span className={styles.customText4}>{t('Dashboard.orderID')}:</span>
              <span>
                <Link
                  to={`/orders/order-details/${item.order_id}`}
                  target="_blank"
                  className={styles.customLink}
                >
                  {item.order_id}
                </Link>
              </span>
            </Typography>
          </Grid>}
          {item?.is_o2_prescription && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.fileType')}:
                </span>
                <span className={styles.customTextBold1}>
                  {FileType[item?.is_o2_prescription]}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.formatted_expiration_date && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {t('Prescriptions.expiration')}:
                </span>
                <span className={styles.customTextBold1}>
                  {formatExpirationDate(item?.formatted_expiration_date)}
                </span>
              </Typography>
            </Grid>
          )}

          {item?.formatted_created_date && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.uploadedOn')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.formatted_created_date}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.created_by_user && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.uploadedBy')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.created_by_user}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.formatted_last_updated_date && (item?.formatted_created_date !== item?.formatted_last_updated_date) && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.updatedOn')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.formatted_last_updated_date}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.last_updated_by_user && (item?.formatted_created_date !== item?.formatted_last_updated_date) && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.updatedBy')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.last_updated_by_user}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.formatted_rejected_on && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.rejectedOn')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.formatted_rejected_on}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.rejected_by && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.rejectedBy')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.rejected_by}
                </span>
              </Typography>
            </Grid>
          )}
          {item?.rejection_reason && (
            <Grid container item>
              <Typography
                className={styles.scopedClassNameLine}
              >
                <span className={styles.customText4}>
                  {" "}
                  {t('Prescriptions.providerComment')}:
                </span>
                <span className={styles.customTextBold1}>
                  {item?.rejection_reason}
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          className={styles.buttonContainer}>
          <PrescriptionListingActions handlePdfView={handlePdfView} handleEditFile={handleEditFile} downloadFile={downloadFile} setFileDeleted={setFileDeleted} setDeletedFileName={setDeletedFileName} item={item} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>)
}

const PrescriptionListing = () => {
  const location = useLocation()
  const [isLoading, setLoading] = useState(true);
  const [isEditDocumentOpen, setIsEditDocumentOpen] = useState(false)
  const { t } = useTranslation();
  const [prescriptionListingData, setPrescriptionListingData] = useState([]);
  const [filetypeOxygenData, setFileTypeOxygenData] = useState([]);
  const [fileTypeOtherFileData, setFileTypeOtherFileData] = useState([]);
  const [isDropZoneOpen, setDropZonePhoneOpen] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileDeleted, setFileDeleted] = useState(null);
  const [deletedFileName, setDeletedFileName] = useState('');
  const [prescriptionFileId, setPrescriptionFileId] = useState('')
  const [isEdited, setIsEdited] = useState(false)
  const [prescriptionsModuleEnabled, setPrescriptionsModuleEnabled] = useState(false);
  const [addPrescriptionSettings, setAddPrescriptionSettings] = useState({});
  const [buttonStates, setButtonStates] = useState({
    oxygen: true,
    referral: true,
    authorization: true,
    planOfCare: true,
    otherPrescription: true,
    otherFile: true,
  });
  const handleButtonClick = (buttonName) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonName]: !prevState[buttonName],
    }));
  };

  const handleFileExpireTime = (id) => {
    const fileData = JSON.parse(localStorage?.getItem(`DownloadedFileData${id}`)) ?? {};
    if (fileData?.url && fileData?.id == id) {
      const url = new URL(fileData?.url)
      const expiresParam = url.searchParams.get('Expires');
      const expireDate = new Date(expiresParam * 1000)
      const currentDate = (new Date())
      if (expireDate - currentDate > 0) {
        return true
      } else {
        localStorage.removeItem(`DownloadedFileData${id}`);
        return false
      }
    }
    else {
      return false
    }
  }

  const handlePdfView = async (item) => {
    const pdfAPIResponseView = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", item.file_id));
    if (pdfAPIResponseView && pdfAPIResponseView.status_code == 200) {
      const pdfUrl = pdfAPIResponseView.data.url;
      window.open(pdfUrl);
    }
  }

  const downloadFile = async (item) => {
    const expireValue = handleFileExpireTime(item.file_id)
    let downloadUrl = '';
    let downloadFileName = ''

    if (expireValue) {
      const expireUrlId = JSON.parse(localStorage.getItem(`DownloadedFileData${item.file_id}`))
      downloadUrl = expireUrlId?.url;
      downloadFileName = expireUrlId?.fileName;
    }
    else {
      const pdfAPIResponseView = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.viewFile.replace("{file_upload_id}", item.file_id));
      if (pdfAPIResponseView && pdfAPIResponseView.status_code == 200) {
        const pdfDownloadUrl = pdfAPIResponseView.data.url;

        localStorage.setItem(`DownloadedFileData${item.file_id}`, JSON.stringify({ url: pdfDownloadUrl, id: item.file_id, fileName: pdfAPIResponseView.data.file_name }))

        downloadUrl = pdfDownloadUrl;
        downloadFileName = pdfAPIResponseView.data.file_name
      }
    }
    fetch(downloadUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const handleFileRemove = async (e) => {
    e.preventDefault();
    const response = await PrescriptionListingApiCall(HTTPMethod.Post, PrescriptionApi.removeFile.replace("{file_upload_id}", fileDeleted?.file_id));

    if (response && response.status_code == 200 && response.data) {
      handlePrescriptionListing();
      handleFilePermission();
      successToast(t("Prescriptions.documentRemovedSuccessfully"));
    }
    setFileDeleted(null);
  }

  const handleEditFile = (item) => {
    setPrescriptionFileId(item);
    setIsEditDocumentOpen(true)
  }
  const handleDropZone = () => {
    setDropZonePhoneOpen(true);
  };
  const handlePrescriptionListing = async () => {
    setLoading(true);
    const orderId = location?.pathname?.split("/").pop();
    const patientId = location?.pathname?.split("/").pop();
    const requestBody = {
      order_id: location?.pathname?.includes('order') ? orderId : null,
      patient_id: /patients|members|residents/.test(location?.pathname) ? patientId : null
    };
    const response = await PrescriptionListingApiCall(
      HTTPMethod.Post,
      PrescriptionApi.prescriptionListing,
      requestBody
    );
    if (response && response.status_code == 200 && response.data) {
      setPrescriptionListingData(response?.data?.files);
      setFileTypeOxygenData(response?.data?.files.filter((file) => file.is_o2_prescription === true));
      setFileTypeOtherFileData(response?.data?.files.filter((file) => file.is_o2_prescription === false));
    } else {
      setPrescriptionListingData([]);
    }
  };
  const handleFilePermission = async () => {
    const orderId = location?.pathname?.split("/").pop();
    const companyId = location?.pathname?.includes('order') ? FileUploadComponent.Order : FileUploadComponent.Patient;
    const response = await PrescriptionListingApiCall(HTTPMethod.Get, PrescriptionApi.getFileUploadPermission + "?component=" + companyId + "&component_id=" + orderId)
    if (response && response.status_code == 200 && response.data) {
      setPrescriptionsModuleEnabled(response.data?.file_upload_access_model?.o2_prescriptions_module_enabled)
      setAddPrescriptionSettings(response.data?.file_upload_access_model);
      setLoading(false);
    }
  }
  useEffect(() => {
    handlePrescriptionListing();
    handleFilePermission();
  }, [isEdited]);


  return (
    <div data-testid="PrescriptionListing">
      <Box sx={{ display: "flex", paddingLeft: 0, paddingRight: 0 }}>
        <CssBaseline />

        <Container className={styles.paddingContainer}>
          <Grid
            container
            className={styles.margin_top_bottom_10px}
            justifyContent="space-between"
          >
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item md={9} xs={12}>
                {prescriptionListingData?.length > 0 &&
                  ((filetypeOxygenData?.length > 0 &&
                    prescriptionsModuleEnabled) ||
                    fileTypeOtherFileData?.length > 0) && (
                    <Grid container spacing={2}>
                      {prescriptionsModuleEnabled && (<>
                        <Grid item>
                          <Button
                            data-testid="oxygen"
                            variant={
                              buttonStates.oxygen ? "contained" : "outlined"
                            }
                            startIcon={buttonStates.oxygen ? <CheckIcon /> : ""}
                            onClick={() => handleButtonClick("oxygen")}
                            className={
                              buttonStates.oxygen
                                ? styles.buttonStylingOnTrue
                                : styles.buttonStylingOnFalse
                            }
                          >
                            {t("Prescriptions.oxygenPrescription")}
                          </Button>
                        </Grid>
                         <Grid item>
                         <Button
                           data-testid="otherFile"
                           variant={
                             buttonStates.otherFile ? "contained" : "outlined"
                           }
                           startIcon={
                             buttonStates.otherFile ? <CheckIcon /> : ""
                           }
                           onClick={() => handleButtonClick("otherFile")}
                           className={
                             buttonStates.otherFile
                               ? styles.buttonStylingOnTrue
                               : styles.buttonStylingOnFalse
                           }
                         >
                           {t("Prescriptions.otherFile")}
                         </Button>
                       </Grid>
                       </>
                      )}
                     
                    </Grid>
                  )}
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                display={"flex"}
                justifyContent={{ md: "flex-end", xs: "flex-start" }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  data-testid="addButton"
                  className={styles.customButton}
                  onClick={handleDropZone}
                >
                  {t("Prescriptions.addNew")}
                </Button>
                <AddPrescription
                  addPrescriptionSettings={addPrescriptionSettings}
                  prescriptionsModuleEnabled={prescriptionsModuleEnabled}
                  isOpen={isDropZoneOpen}
                  onClose={() => {
                    setDropZonePhoneOpen(false);
                    handlePrescriptionListing();
                    handleFilePermission();
                  }}
                  setFileData={setFileData}
                  fileData={fileData}
                />
              </Grid>
            </Grid>
          </Grid>
          {prescriptionListingData &&
            prescriptionListingData?.length > 0 &&
            ((filetypeOxygenData?.length > 0 && prescriptionsModuleEnabled) ||
              fileTypeOtherFileData?.length > 0) ? (
            <div>
              <Grid>
                {prescriptionsModuleEnabled &&
                  filetypeOxygenData?.length > 0 &&
                  buttonStates.oxygen && (
                    <div className={styles.mainHeadingText}>
                      {t("Prescriptions.oxygenPrescription")}
                    </div>
                  )}
                {prescriptionsModuleEnabled &&
                  buttonStates.oxygen &&
                  filetypeOxygenData?.length > 0 &&
                  filetypeOxygenData?.map((item, index) => (
                    <DocumentListingData
                      item={item}
                      key={item?.file_id}
                      prescriptionsModuleEnabled={prescriptionsModuleEnabled}
                      buttonStates={buttonStates.oxygen}
                      filetypeOxygenData={filetypeOxygenData}
                      index={index}
                      fileTypeData={filetypeOxygenData}
                      t={t}
                      handleEditFile={handleEditFile}
                      handlePdfView={handlePdfView}
                      downloadFile={downloadFile}
                      handleFileRemove={handleFileRemove}
                      setFileDeleted={setFileDeleted}
                      setDeletedFileName={setDeletedFileName}
                    />
                  ))}
                {buttonStates.otherFile && prescriptionsModuleEnabled &&
                  fileTypeOtherFileData?.length > 0 && (
                    <div className={styles.mainHeadingText}>
                      {t("Prescriptions.otherFile")}
                    </div>
                  )}
                {buttonStates.otherFile &&
                  fileTypeOtherFileData?.length > 0 &&
                  fileTypeOtherFileData?.map((item, index) => (
                    <DocumentListingData
                      item={item}
                      index={index}
                      fileTypeData={fileTypeOtherFileData}
                      t={t}
                      key={item?.file_id}
                      handleEditFile={handleEditFile}
                      handlePdfView={handlePdfView}
                      downloadFile={downloadFile}
                      handleFileRemove={handleFileRemove}
                      setFileDeleted={setFileDeleted}
                      setDeletedFileName={setDeletedFileName}
                    />
                  ))}
              </Grid>
            </div>
          ) : location?.pathname?.includes("patients") ||
            location?.pathname?.includes("members") ? (
            <div className={styles.module}>
              <NoData
                message={
                  isLoading ? (
                    <img alt="Loading..." src={loader} />
                  ) : location.pathname.includes("patients") ? (
                    t("Prescriptions.noDocumentMessagePatientDocument")
                  ) : (
                    t("Prescriptions.noDocumentMessageMemberDocument")
                  )
                }
              />
            </div>
          ) : (
            <div className={styles.module}>
              <NoData
                message={
                  isLoading ? (
                    <img alt="Loading..." src={loader} />
                  ) : (
                    t("Prescriptions.noDocumentMessage")
                  )
                }
              />
            </div>
          )}
          {!isLoading &&
            ((filetypeOxygenData?.length > 0 && prescriptionsModuleEnabled) || fileTypeOtherFileData?.length > 0)
             &&
            ((buttonStates.oxygen && filetypeOxygenData?.length === 0) ||
              (buttonStates.otherFile && fileTypeOtherFileData?.length === 0))
               &&
            !((buttonStates.oxygen && filetypeOxygenData?.length > 0) ||
              (buttonStates.otherFile && fileTypeOtherFileData?.length > 0)) 
              &&
             (<div className={styles.module}>
                <NoData message={"There is no document in result."} />
              </div>
             )}
           
        </Container>
        {isEditDocumentOpen && (
          <EditPrescription
            isEditDocumentOpen={isEditDocumentOpen}
            currentListData={prescriptionFileId}
            setIsEdited={setIsEdited}
            addPrescriptionSettings={
              addPrescriptionSettings.o2_prescriptions_module_enabled
            }
            handleClose={() => {
              setIsEditDocumentOpen(false);
              handlePrescriptionListing();
              handleFilePermission();
            }}
          >
            {" "}
          </EditPrescription>
        )}
      </Box>

      <CommonAlert
        contentText={deletedFileName}
        headerText="Remove Document"
        okText="Proceed"
        cancelText="Cancel"
        isOpen={!!fileDeleted}
        handleSubmit={handleFileRemove}
        okButtonStyle={"red"}
        handleCancel={() => {
          setFileDeleted(null);
        }}
      />
    </div>
  );
};
export default PrescriptionListing;
