import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';

const CustomGrid = (props) => {
  const { rows, columns, getRowId, page, rowsPerPage, columnVisibilityModel, setColumnVisibilityModel, showSlots = true } = props.data;
  const [data, setData] = useState([]);

  const theme = useTheme();

  const CustomGridPanel = {
    "& .MuiInputBase-root": {
      fontSize:'13px',
    },
    "& .MuiFormLabel-root": {
      fontSize:'13px',
    },
    "& .MuiButtonBase-root ": {
      fontSize:'13px',
    },
    "& .MuiTypography-root ": {
      fontSize:'13px',
    },
    '& .MuiSwitch-root': {
    width: 26,
    height: 14,
    padding: 0,
    display: "flex",
    fontSize: '13px',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 11,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(12px)",
      },
    },
    "& .MuiSwitch-switchBase": {
        top:-2.4,
       padding: '4px 4px 4px 0px', 
      "&.Mui-checked": {
        transform: "translateX(13px)",
        color: "var(--bg-white)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "var(--color-base)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 11,
      height: 11,
      borderRadius: 8,
      margin: '0 0 0 1px',
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  },
  }

  const fetchData = useCallback(() => {
    setData(rows);
  }, [page, rows, rowsPerPage]); 

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="columnClass" sx={{color:'var(--color-base)'}}/>
      </GridToolbarContainer>
    );
  }


  const getTogglableColumns = (columns, hiddenFields = []) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  

  return (
    <Box data-testid="datagrid">
      <DataGrid
       disableVirtualization
        width="100%"
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell":{
            maxHeight: "unset !important",
          },
          "& .MuiDataGrid-row":{
            maxHeight: "unset !important",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "transparent",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
            fontSize: "13px",
            minWidth: "100%",
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            left: "0px",
            top: "0px",
            background: "#E5E5E5",
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontStyle: "normal",
            alignItems: "center",
            fontWeight: "700",
            fontSize: "13px",
            lineHeight: "16px",
            display: "flex",
            color: "#171C26",
            whiteSpace: "pre-wrap",
          },
          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#F5F5F5",
          },
          "&>.MuiDataGrid-main": {
            "& div div div div >.MuiDataGrid-cell": {
              borderBottom: "none",
            },
          },
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "pre-wrap",
            },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            minWidth: "100%",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto",
            height: "auto",
            position: "relative",
            minHeight:'calc(50vh - 120px)'
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            minHeight: "70px !important",
          },
          "@media (max-width: 600px)": {
            "& .MuiDataGrid-root": {
              fontSize: "13px",
              minWidth: "100%",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "13px",
              lineHeight: "12px",
            },
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              overflow: "visible",
              whiteSpace: "pre-wrap",
            },
          },
        }}
        rows={data}
        getRowId={getRowId}
        columns={columns}
        checkboxSelection={false}
        hideFooter
        disableColumnMenu
        disableRowSelectionOnClick
        slots={{
          toolbar: showSlots ? CustomToolbar : null,
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns: getTogglableColumns,
            disableHideAllButton: false,
            disableShowAllButton: false,
          },
            panel: {
              sx: CustomGridPanel
            },  
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel)
        }
        }
      />
    </Box>
  );
};
export default CustomGrid;
