import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const CompanyName = ({ companyOption, companyDataName, setCompanyDataName, handleChangePatientsFilter }) => {
    const { t } = useTranslation();

    const handleCompanyChange = (event, value) => {
        setCompanyDataName(value);
        handleChangePatientsFilter(event, "company");
    }

    return (
        <div className="mb-6 flex" data-testid="company-filter">
            <Box sx={{ width: '100%' }}>
                <Autocomplete
                    id="companyName"
                    data-testid="companyName"
                    popupIcon={<KeyboardArrowDownIcon />}
                    options={companyOption?.length > 0 ? companyOption : []}
                    getOptionLabel={(option) => option?.name}
                    noOptionsText={t("Dashboard.noDataFound")}
                    isOptionEqualToValue={(option, value) => option?.name == value?.name}
                    clearOnBlur={false}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("SupportForm.company")}
                            InputProps={{
                                ...params.InputProps,
                                style: { color: "0,0,0,.66" },
                            }}
                        />
                    )}
                    value={companyDataName}
                    size="small"
                    onChange={handleCompanyChange}
                />
            </Box>
        </div>
    )
};
