import React, { useEffect, useState } from 'react';
import styles from './location-filter.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { HTTPMethod, callApi } from '../../../../services/HttpService/HttpService';
import { PatientsApi } from '../../../../constants/ApiConstants';

const LocationFilter = ({ props }) => {
  const { t } = useTranslation();
  const { addBranchOption, setAddBranchOption, orderBranchId, setOrderBranchId, handleChangePatientsFilter } = props;
  const [branch, setBranch] = useState('All');

  const handleAddBranchInputChange = (event, value) => {
    setBranch(value);
    setOrderBranchId(null);
  };

  const handleAddBranchChange = (event, value) => {
    if (value) {
      setOrderBranchId(value);
      handleChangePatientsFilter(event, "branch", value?.location_name);
    } else {
      setBranch('All');
      setOrderBranchId(addBranchOption[0]);
      handleChangePatientsFilter(event, "branch", 'All');
    }
  };

  const handleAddBranchFilter = (option, state) => {
    if (option.length > 0 && state.inputValue && state.inputValue.trim().length >= 2 && branch != 'All') {
      return option.filter((item) => String(item.location_name).toLowerCase().includes(state.inputValue?.toLowerCase().trim()));
    }
    return option;
  };

  const handleBranchApi = async () => {
    const response = await callApi(
      HTTPMethod.Get,
      PatientsApi.patientsHospiceBranchSearch + "?LocationName="
    );
    if (response && response.status_code == 200 && response.data) {
      let locations = [{
        location_name: "All",
        location_id: 0
      }];
      locations.push(...response.data.location_info);
      setAddBranchOption(locations)
      setOrderBranchId(locations[0]);
      setBranch('All');
    }
  };

  useEffect(() => {
    handleBranchApi();
  }, []);

  const handleLocationOnBlur = (e) => {
    const isValidBranch = addBranchOption.some(
      (option) => option.location_name.toLowerCase() === branch.toLowerCase()
    );
  
    if (!isValidBranch) {
      setBranch('All');
      setOrderBranchId(addBranchOption[0]);
      handleChangePatientsFilter(e, "branch", 'All');
    }
  }
  
  return (
    <div className={`${styles.LocationFilter} mb-6 flex`} data-testid="LocationFilter">
      <Box>
        <Autocomplete
          id="addNewBranchFilter"
          data-testid="addNewBranchFilter"
          popupIcon={<KeyboardArrowDownIcon />}
          options={addBranchOption?.length > 0 ? addBranchOption : []}
          getOptionLabel={(option) => option?.location_name || ''}
          noOptionsText={t("Dashboard.noDataFound")}
          isOptionEqualToValue={(option, value) => option?.location_id === value?.location_id}
          inputValue={branch}
          onInputChange={handleAddBranchInputChange}
          filterOptions={handleAddBranchFilter}
          clearOnBlur={false}
          onBlur= {handleLocationOnBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Dashboard.branch")}
              InputProps={{
                ...params.InputProps,
                style: { color: "0,0,0,.66" },
              }}
            />
          )}
          value={orderBranchId}
          size="small"
          onChange={handleAddBranchChange}
        />
      </Box>
    </div>
  );
};

export default LocationFilter;
