const ordersStatusColors = [
  "var(--color-yellow)",
  "var(--color-red)",
  "var(--color-green)",
  "var(--color-orange)",
  "var(--color-base)",
  "var(--color-grey)",
  "var(--color-lightgrey)",
];
const StatusColor = (orderStatus) => {
  switch (orderStatus) {
    case "Pending Approval": case "Pendingapproval": case "PendingApproval": case "Acknowledged" : case "Pending Review": 
      return ordersStatusColors[0];
    case "ApprovalEscalated": case "Approval Escalated": case "Refused": case "Not Necessary": case "Disposable": case "Lost": case "lost": case "LiveDischarge": case "Live Discharge": case "Discharged (No Reason)": case "NoReasonDischarge": case 'Expired': case 'Rejected': case 'Noncompliant':
      return ordersStatusColors[1];
    case "On my way": case "Active": case "Accepted":
      return ordersStatusColors[2];
    case "Pending": case "En-Route": case "Shipping": case "En route": case "En-route": case "En Route":  case "Picking Up": case "Not Approved": case "Enroute":
      return ordersStatusColors[3];
    case "Delivered":  case "Picked Up": case "Completed":
      return ordersStatusColors[4];
    case "Received": case "Submitted": case "Queued": case "Unknown": case "Inactive":
      return ordersStatusColors[5];
    case "Canceled": case "Incomplete":
      return ordersStatusColors[6];
    default:
      return ordersStatusColors[0];
  }
}

export default StatusColor;

