import { useEffect, useRef } from "react";
import { HTTPMethod, callApi as notificationCount } from "../../../services/HttpService/HttpService";
import { NotificationApi } from "../../../constants/ApiConstants";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationCount } from "../../../redux/AuthSlice/AuthSlice";

export const fetchNotificationCountData = async (dispatch) => {
    const response = await notificationCount(HTTPMethod.Get, `${NotificationApi.notificationListing}?OnlyUnReadCount=true`)
    if (response?.data) {
        dispatch(GetNotificationCount({ notificationCount: {totalCount: response?.data?.total_count, filteredCount: response?.data?.filtered_count}}));
    } else {
        dispatch(GetNotificationCount({ notificationCount: {totalCount: 0, filteredCount: 0}}));
    }
}
function useNotificationCount() {
    const dispatch = useDispatch();
    const isSessionInactive = useSelector(state=>state.authState.isSessionInactive);
    const interval = 60;
    const timeoutId = useRef(null);
    useEffect(() => {
        if (!isSessionInactive && localStorage.getItem('accessToken')) {
                timeoutId.current = setInterval(() => {
                    fetchNotificationCountData(dispatch)
                }, interval * 1000)
                fetchNotificationCountData(dispatch)
        }
        else {
            clearInterval(timeoutId.current);
        }
        return () => {
            if (timeoutId.current) {
                clearInterval(timeoutId.current);
            }
        };
    }, [isSessionInactive, localStorage.getItem('accessToken')])
}

export default useNotificationCount;