import React, { useEffect, useState } from 'react';
import demographicStyles from "./Demographics.module.scss"
import styles from '../../pages/orders-module/OrdersDetails/OrdersDetails.module.scss';
import { LineOfBusinessEnum } from '../../constants/BusinessConstants';
import { Chip, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DischargeReason, GenderEnum, OrderDetailsPatientEnum } from '../../common/enums';
import { EMRTooltip } from '../MuiCustomComponent/MuiCustomComponent';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { commonConstants } from '../../common/constants';
import { CustomFields } from '../../common/customFields/customFields';
import { useSelector } from 'react-redux';

const Demographics = ({ patientInformation, hasOtherOrder, orderId, branchId = 0, dxCode=null, authCode=null, patientId=null }) => {
const { t } = useTranslation(); 
const [patientInfo, setPatientInfo] = useState(patientInformation);
const companyId = useSelector((state) => state.authState.userData?.data?.company_settings[0]?.company_id ?? 0);
const navigate = useNavigate();
  const handleViewOtherOrderPage = (id) => {
    localStorage.setItem("drawerSelectedItem", "Orders");
    navigate("/view-other-orders/" + id);
  };

  useEffect(() => {
    const getCustomFields = async () => {
      const customFields = await CustomFields(branchId, companyId);
      if(customFields.length > 0){
        setPatientInfo({
          ...patientInfo
      });
      }
    };
    getCustomFields();
  }, [patientInformation])

return (
  <div className={demographicStyles.Demographics} data-testid="Demographics">
    <Grid container direction="column">
      {patientId && <Grid item>
        <Typography variant="h6" component="h2" data-testid="patientDemographics">
          <Box className="customDemographicsBox">
           <Box className="customDemographicsBoxItem">
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                className={styles.customTypography}
              >
                <div className={`${styles.marginClass}`}>
                  <Link
                    to={`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${patientId}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }} className={demographicStyles.customPatientLink}
                  >
                    {patientInfo?.patient_last_name},{" "}
                    <span> </span>
                    {
                      patientInfo?.patient_first_name
                    }{" "}
                    {
                      patientInfo
                        ?.patient_middle_initial
                    }{" "}
                  </Link>
                </div>
                <Chip
                  label={
                    OrderDetailsPatientEnum[
                    patientInfo?.patient_status
                    ]
                  }
                  size="small"
                  className={
                    OrderDetailsPatientEnum[
                      patientInfo?.patient_status
                    ] === "Active"
                      ? `${styles.myCustomClass2} ${styles.myCustomChip}`
                      : OrderDetailsPatientEnum[
                        patientInfo?.patient_status
                      ] === "Inactive"
                        ? `${styles.myCustomClass2} ${styles.myCustomInactiveChip}`
                        : `${styles.myCustomClass2} ${styles.myCustomOtherStatusChip}`
                  }
                />
                {patientInfo?.emr_integration_flag && (
                  <EMRTooltip
                    title={<span className={styles.tooltipStyling}>{t("PatientDetails.EMRdescription")} </span>}
                    placement="right"
                  >
                    <Chip
                      label={t("PatientDetails.EMR")}
                      size="small"
                      alignItems={{ sm: "auto" }}
                      className={styles.emr}
                    />
                  </EMRTooltip>
                )}
              </Typography>
            </Box>
          </Box>
        </Typography>
      </Grid>}
      {patientInfo?.patient_id && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>
              {t("PatientsListing.dmeTrackId")}:{" "}
            </span>
            <span className={styles.customTextBold1}>
              {patientInfo?.patient_id}
            </span>
          </Typography>
        </Grid>
      )}
      {patientInfo?.mrn && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>
              {t("PatientsListing.mrn")}:{" "}
            </span>{" "}
            <span className={styles.customTextBold1}>
              {patientInfo?.mrn}
            </span>
          </Typography>
        </Grid>
      )}
      {LineOfBusinessEnum.patientToMember?.toLocaleLowerCase() == "member" && patientInfo?.sex && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>{t("PatientsDetails.sex")}:</span>
            <span className={styles.customTextBold1}>
              {GenderEnum[patientInfo?.sex]}
            </span>
          </Typography>
        </Grid>
      )}
      {(patientInfo?.date_of_birth !== commonConstants.defaultDate && patientInfo?.date_of_birth) && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>
              {t("PatientsDetails.dateOfBirth")}:{" "}
            </span>
            <span className={styles.customTextBold1}>
              {patientInfo?.date_of_birth}
            </span>
          </Typography>
        </Grid>
      )}
      {patientInfo?.admission_date && patientInfo?.admission_date !== commonConstants.defaultDate && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>
              {" "}
              {t("PatientsDetails.admissionDate")}:
            </span>
            <span className={styles.customTextBold1}>
              {patientInfo?.admission_date?.split(" ")[0]}
            </span>
          </Typography>
        </Grid>
      )}
      {patientInfo?.height && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>{t('AddPatient.height')}:</span>
            <span className={styles.customTextBold1}>
              {patientInfo?.height}
            </span>
          </Typography>
        </Grid>
      )}

      {patientInfo?.weight && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>{t('AddPatient.weight')}:</span>
            <span className={styles.customTextBold1}>
              {patientInfo?.weight.includes("lbs")
                ? patientInfo?.weight.replace(
                  "lbs",
                  ""
                ) + " lbs"
                : patientInfo?.weight + " lbs"}
            </span>
          </Typography>
        </Grid>
      )}
      {patientInfo?.discharge_date &&
        !patientInfo?.discharge_date.includes(
          commonConstants.defaultDate
        ) &&
        (
          <Grid item>
            <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
              <span className={styles.customText4}>
                {" "}
                {t("PatientsListing.dischargeDate")}:
              </span>
              <span className={styles.customTextBold1}>
                {patientInfo?.discharge_date}
              </span>
            </Typography>
          </Grid>
        )}
      {patientInfo?.discharge_reason !== " " && patientInfo?.discharge_reason && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>
              {" "}
              {t("PatientsDetails.dischargeReason")}:
            </span>
            <span className={styles.customTextBold1}>
              {DischargeReason[patientInfo?.discharge_reason]}
            </span>
          </Typography>
        </Grid>
      )}
      {dxCode && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>{t('PatientsDetails.dxCode')}:</span>
            <span className={styles.customTextBold1}>
              {dxCode}
            </span>
          </Typography>
        </Grid>
      )}
      {authCode && (
        <Grid item>
          <Typography
            className={`${styles.scopedClassNameLine} ${demographicStyles.customMargin}`} >
            <span className={styles.customText4}>{t('PatientsDetails.authCode')}:</span>
            <span className={styles.customTextBold1}>
              {authCode}
            </span>
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography
          className={styles.scopedClassNameLine}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          {hasOtherOrder && (
            <span className={styles.customLink} >
              <a onClick={() => {
                handleViewOtherOrderPage(orderId);
              }} >
                <div style={{ display: "flex" }}>
                  <span>{t('PatientsDetails.viewOtherOrder')}</span>
                  <span>
                    <KeyboardArrowRightIcon />
                  </span>
                </div>
              </a>
            </span>
          )}
        </Typography>
      </Grid>
    </Grid>
  </div>)
}


export default Demographics;
