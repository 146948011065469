export const OtpType = {
    Unspecified: 0,
    VerifyAccount: 1,
    ResetPassword: 2,
    TwoFactor: 3,
    EditEmail: 4,
    AddPhoneNumber: 5,
    EditPhoneNumber: 6,
    LoginViaOTP: 7
}
export const orderStatus = {
    'PendingApproval': "PA",
    'Processing': "QP",
    'Queued': "QU",
    'Received': "R",
    'Acknowledged': "A",
    'EnRoute': "T",
    'OnMyWay': "OW",
    'Completed': "D",
    'NotApproved': "NA",
    'Canceled': "C",
    'PickingUp': "U"
};
export const statusEnum = {
    'Pendingapproval' : 'Pending approval',
    'PendingApproval' : 'Pending approval',
    'Pending Approval' : 'Pending Approval',
    'ApprovalEscalated' :'Approval escalated',
    'Acknowledged' : 'Acknowledged',
    'On my way' : 'On my way',
    'Received' : 'Received',
    'Delivered' : 'Delivered',
    'En route' : 'En route',
    'En-Route':'En-Route',
    'PickedUp':'Picked Up',
    'Queued' : 'Queued',
    'Canceled':'Canceled',
    'Submitted':'Submitted',
    'InProgress': 'InProgress',
    'Picking Up': 'Picking Up',
}

export const PatientsStatusEnum ={
    "Active": "Active",
    "DeathDischarge": "Expired",
    "Expired": "Expired",
    "LiveDischarge": "Live Discharge",
    "InActive": "Inactive",
    "NoReasonDischarge": "Discharged (No Reason)"
}
export const OrderDetailsPatientEnum = {
  "Live Discharge": "Live Discharge",
  "LiveDischarge":"Live Discharge",
  "Death Discharge": "Expired",
  "Discharged (No Reason)": "Discharged (No Reason)",
  "InActive": "Inactive",
  "Active": "Active",
  "Expired": "Expired",
};
export const PatientsDetailsStatusEnum ={
    "Active": "Active",
    "DeathDischarge": "Death Discharge",
    "LiveDischarge": "Live Discharge",
    "InActive": "Inactive",
    "Discharged (No Reason)": "Discharged (No Reason)",
    "Live Discharge" : "Live Discharge",
    "Death Discharge": "Death Discharge",
    "Expired": "Expired",
    "NoReasonDischarge": "Discharged (No Reason)"
}
export const RelationShipEnum = {
    1: "Caregiver",
    2: "Child",
    3: "Immediate Family",
    4: "Other",
    5: "Parent",
    6: "Self",
    7: "Spouse",
    8: "Unknown",
};

export const GenderEnum = {
    "M" : "Male",
    "F" : "Female"
}
export const FacilityTypeEnum = {
    'ACH': "Adult Care Home",
    'AL': "Assisted Living",
    'LTC': "Long Term Care",
    'RES': "Residence",
}
export const PhoneEnum = {
    1:"Mobile",
    2:"Landline"
}
export const LiterFlowTypeEnum = {
    1: "PRN",
    2: "Continuous"
}

export const RelationShipListingEnum = {
    "Caregiver" : "Caregiver",
    "Child" : "Child",
    "Other" : "Other",
    "Parent" : "Parent",
    "Self" : "Self",
    "Spouse" : "Spouse",
    "Unknown" : "Unknown",
    "ImmediateFamily" : "Immediate Family"
};
export const AddressTypeEnum = {
    "RES":"Residence",
    "FCH":"Facility"
};
export const InfectiousDiseaseLevelEnum = {
    0: "None",
    1: "Level 1: Gloves",
    2: "Level 2: Gloves, Mask",
    3: " Level 3: Gloves, Mask, Suit",
    4: "Level 4: Gloves, Mask, Suit, Respirator"
};
export const OrderTypeEnum = {
    "Delivery": "Delivery",
    "PickUp": "Pick up",
    "ServiceRequest": "Service request",
    "Supplies": "Supplies"
}
export const OrderStatusEnum = {
    "Open": "Open",
    "PendingApproval": "Pending approval",
    "Processing": "Processing",
    "Queued": "Queued",
    "Received": "Received",
    "Acknowledged": "Acknowledged",
    "EnRoute": "En route",
    "OnMyWay": "On my way",
    "Completed": "Completed",
    "NotApproved": "Not approved",
    "Canceled": "Canceled",
    "Delivered": "Delivered",
    "Picked": "Picked Up",
    "PickingUp": "Picking Up"
}
export const PriorityNameEnum = {
    1: "STAT (Within 2 Hours)",
    2: "Urgent (Within 4 Hours)",
    3: "Priority (Within 6 Hours)",
    4: "Routine (Same day if ordered before 3 PM local time)",
    5: "Standard (Within 24 Hours)",
    6: "Coordination (Scheduled Date and Time)",
    7: "Drop Ship (Freight Times)"
}
export const OrdersTypeEnum = {
    "D" : "Delivery",
    "S" : "Supplies",
    "SR": "Service",
    "P": "Pickup",
    "PA": "Pickup all"
}

export const OrdersDetailsSupplyEnum = {
       1 :  "InProgress", // being created
       2 : "WaitingApproval",
       3 : "PendingRevision",
       4 : "Revised",
       5 : "Approved",
       6 : "Hold",
       7 : "Closed",
       8 : "Canceled",
       9 : "Submitted",  // sent to remote system but we haven't gotten confirmation back with their order number (for use with aync pattern)
       10 : "Ordered",
       11 : "Shipping",
       12 : "Delivered",
       13 : "DeliveryException",
       14 : "Queued",
}
export const OrderReasonEnum = {
    "N": "First Delivery",
    "F": "O2 Equipment Repair/Replace",
    "S": "Add On Oxygen",
    "C": "Change of Address",
    "E": "Respiratory Distress", // E - Emergency remove when we get updated enum after discussion with Backend and BA
    "A": "Add On Respiratory/Oxygen",
    "R": "Service: General Equipment",
    "Z": "Split Order",
    "B": "Billing Purposes",
    "I": "Disposable Items",
    "P": "Respite Stay",
    "H": "Urgent Admission",
    "Y": "Service: Room Change",
    "O": "Other",
    "D": "Service: Bed Equipment",
    "L": "Service: Weighing Service",
    "U": "Hi-Tech Respiratory",
    "X": "Quote",
    "W": "Switchout",
    "V": "Conversion",
    "T": "Recurring Order",
    "K": "Internal Transfer",
    "G": "Add on General",
    "J": "Patient Safety",
    "M": "Service: Assessment",
    "Q": "Service: Respiratory Equipment",
    "0": "Re-admit"
};
export const  OrderReasonPickUpEnum = {
    "C": "Equipment Move",
    "X": "D&D",
    "D": "Expired",
    "L": "Live Discharge",
    "U": "Not Needed",
    "S": "Equipment Exchange",
    "O": "Lost Attempt 1",
    "T": "Lost Attempt 2",
}
export const OrderReasonUpdatedEnum = {
    "First Delivery": "N",
    "O2 Equipment Repair/Replace": "F",
    "Add On Oxygen": "S",
    "Change of Address": "C",
    "Respiratory Distress": "E",
    "Add On Respiratory/Oxygen": "A",
    "Service: General Equipment": "R",
    "Split Order": "Z",
    "Billing Purposes": "B",
    "Disposable Items": "I",
    "Respite Stay": "P",
    "Urgent Admission": "H",
    "Service: Room Change": "Y",
    "Other": "O",
    "Internal Transfer": "K",
    "Recurring Order": "T",
    "Conversion": "V",
    "Switchout": "W",
    "Patient Safety": "J",
    "Add On General": "G",
    "Service: Respiratory Equipment": "Q",
    "Service: Bed Equipment": "D",
    "Service: Weighing Service": "L",
    "Service: Assessment": "M",
    "Hi-Tech Respiratory": "U",
    "Quote": "X",
    "Re-admit": "0"
};

export const FrequencyEnum = {
    Weekly: 1,
    Monthly: 2
};
export const ApprovalStatus = {
    "PendingApproval": 'Pending Approval',
    "Approved": 'Approved',
    "PartiallyApproved": 'Partially Approved',
    "NotApproved": 'Not Approved',
    "DoNotSendToDme": 'Do Not Send To Dme',
    "Canceled": 'Canceled',
    "PendingApprovalUM": 'Pending Approval UM',
    "ApprovedUM": 'Approved UM',
    "PartiallyApprovedUM": 'Partially Approved UM',
    "NotApprovedUM": 'Not Approved UM',
    "DoNotSendToDmeUM": 'Do Not Send To DMEUM',
    "CanceledUM": 'Canceled UM',
    "ConsultInProgressUM": 'Consult in Progress UM',
  };
  export const EscalationStatusEnum ={
    "FirstLevel" : "First Level",
    "SecondLevel" : "Second Level",
    "NotEscalated" : "Not Escalated"

  }
  export const OrderApprovalStatus ={
        1: "Pending Approval",
        3: "Approved",  
        4: "Partially Approved",  
        5: "Not Approved",
        6: "Do Not Send To Dme",
        7: "Canceled",
        8: "Pending Approval UM",
        9: "Approved UM",
        10: "Partially Approved UM",
        11: "Not Approved UM",
        12: "Do Not Send To DMEUM",
        13: "Canceled UM",
        14: "Consult In Progress UM"
 }
 export const OrderEscalationStatus = {
    1: "First Level",
    2: "Second Level",
    0: "Not Escalated"
 }
 export const FileType = {
    'true': "Oxygen Prescription",
    'false': "Other File"
 }
 export const DocumentType = {
    "oxygenPrescription" : 1, 
    "otherFile" : 2, 
  }

 export const StatusFileUpload = {
    "PENDINGREVIEW": "Pending Review",
    "ACCEPTED": "Accepted",
    "REJECTED": "Rejected",
    "EXPIRED": "Expired"
 }
 export const FileUploadComponent = {
    "Patient":1,
    "Order":2,
    "Ordering":3,
    "EditOrder":4
 }
 export const DischargeReason = {
    "L": "Live Discharge",
    "D": "Expired"
 }
 export const EntityTypeForFollow = {
    "Order" : 1,
    "Patient" : 2,
    "RecurringOrder" : 3,
    "SupplyOrder": 4
}
export const FollowStatus = {
    "Unfollowed" :  0,
    "Followed" : 1
}

export const SupplierSpecial = {
    1: "Medline",
    2: "McKesson"
}
export const SuppliesDeliveryMethod = {
     0: "None Selected",
     1: "Patient",
     2: "Patient Care Of Branch",
     3: "Branch"
}

export const feedBackOrderType = {
    "Delivery":1,
    "Pickup": 2,
    "Mixed": 3,
    "Service": 4
}

export const feedBackOrderTypeDisplay = {
    1: "delivery",
    2: "pickup",
    3: "mixed",
    4: "service"
}
export const feedBackOrderTypeDisplayItems = {
    1: "delivery",
    2: "pickup",
    3: "order fullfilment",
    4: "service"
}
export const feedBackOrderTypeDisplayLabel = {
    1: "delivered",
    2: "picked up",
    3: "order fullfilment",
    4: "serviced",
    5: "all"
}

export const feedbackInputType= {
    Bool: 1,
    Text: 2,
    Rating: 3,
    Dropdown: 4,
    CheckBox: 5,
    RatingWithNA: 6,
    YesNo: 7,
    Heading: 8,
}

export const deliveryOrderType = {
    PickUp: 'P',
    PickUpAll: 'PA',
    Service: 'SR',
    Supplies: 'S',
    DeliveryServiceMix: ['D','SR'],
    Delivery: 'D',
    DeliveryService: 'DS'
}

export const equipmentOrderType = {
    PickUp: 'Pickup',
    Service: 'Service',
}

export const dischargeReasonEnum = {
    LiveDischarge: "L",
    Expired: "D"
}
export const OrderConfiguration = {
    Delivery: "D",
    Service:"SR",
    PickUp: "P",
    PickUpAll: "PA",
    ServiceRequest: "SR",
    Supplies: "S",
    DeliveryAndService: "D,SR",
};
export const OrderEnum = {
    Delivery: "Delivery",
    PickUp: "Pick Up",
    ServiceRequest: "Service Request",
    Supplies: "Supplies",
    PickUpAll: "Pick up all"
}

export const configurationCompanySetting = {
    EnRouteEtaVisible: "EnRouteEtaVisible",
    AllowBackdatingOrders: "AllowBackdatingOrders"
}

export const walkthroughVideoType = {
    EditOrder: 1,
    Follow: 2,
    Profile: 3,
    Service: 4
}
export const suggestionReasons = {
    1: "New Admit",
    2: "Diagnosis",
    3: "Demographic",
    4: "Symptom"
}

export const WalkThroughPathType = {
    Orders: 'Orders',
    NewOrder: 'NewOrder',
    OrdersDetailsTab: 'OrderDetailsTab',
    Patients: 'Patients'
};

export const shareFeedbackNotification = {
    EntityType31: 31,
    EntityType14: 14,
}
export const orderAddressStatus = {
    Delete:"D",
    Add:"A",
}