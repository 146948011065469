import React from 'react';
import styles from './billing-month-filter.module.scss'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { formatReportDate } from '../../../../common/Utils/CommonFunctions/CommonFunctions';
import { commonConstants } from '../../../../common/constants';

const BillingMonthFilter = ({ selectedBillingDate, setSelectedBillingDate, handleChangePatientsFilter, validBillingDate, setValidBillingDate}) => {
  const currentDate = dayjs();
  const threeYearsAgo = currentDate.subtract(3, 'year');

  const handleDateChange = (newValue) => {
    if (newValue ) {
      if(newValue.toLocaleString() === commonConstants.invalidDate || newValue?.$y < commonConstants.minValidDate || newValue?.$y >= commonConstants.maxValidDate ){
        setValidBillingDate(true)
      }else{
        setValidBillingDate(false)
      }
      const billingDateValue = formatReportDate(newValue)
      setSelectedBillingDate(newValue);
      handleChangePatientsFilter(newValue, "billingDate", billingDateValue)
    }
  };
  return (
  <div className={styles.BillingMonthFilter} data-testid="BillingMonthFilter">
    <div className={styles.customMarginBottom}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
       <DatePicker
        label="Billing Month"
        openTo="month"
        views={['year', 'month']}
        value={selectedBillingDate}
        onChange={handleDateChange}
        minDate={threeYearsAgo}
        maxDate={currentDate}
        format="MMM YYYY"
        slotProps={{ textField: { size: 'small' }, 
        popper: {
          popperOptions: {
            modifiers: [
              {
                name: 'applyCustom',
                enabled: true,
                phase: 'beforeWrite',
                requires: ['computeStyles'],
                fn: ({ state }) => {
                  state.styles.popper.height = '358px';
                  if (state.placement.includes('top-start')) {
                    state.styles.popper = {
                      ...state.styles.popper,
                      display: 'flex',
                      alignItems: 'flex-end',
                    };
                  }
                  if (state.placement.includes('bottom')) {
                    state.styles.popper = {
                      ...state.styles.popper,
                      display: 'block',
                    };
                  }
                },
              },
            ],
          }
        },}}
        sx={{ width: '50%' }}
        />
        </LocalizationProvider>
        </div>
  </div>
  );
}

export default BillingMonthFilter;
