import React, { useState } from "react";
import StyleSection from "./card.module.scss";
import { Link as RouterLink } from "react-router-dom";
import { Chip, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import StatusColor from "../../common/Utils/StatusColor/StatusColor";
import { LineOfBusinessEnum } from "../../constants/BusinessConstants";
import {useTheme } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { regExPattern } from "../../common/constants";
import { useTranslation } from "react-i18next";

export const Card = ({ children, title, description = "", viewAll = "", quickFilterEnable = "" }) => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme?.breakpoints?.down('sm')) || false;
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const { t } = useTranslation();
    const toggleAccordian = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
    return (
        <div className={StyleSection.section}>
            {isMobileScreen ?
                <div className="flexJustifyBetween marginBottom10px flexDirectionColumn">
                    <div className="flexJustifyBetween">
                        <h3>{title}</h3>
                        <div>
                            {isAccordionOpen && <ExpandLessIcon data-testid="expandLessIcon" onClick={toggleAccordian} style={{ cursor: "pointer", marginLeft: "auto" }} />}
                            {!isAccordionOpen && <ExpandMoreIcon data-testid="expandMoreIcon" onClick={toggleAccordian} style={{ cursor: "pointer", marginLeft: "auto" }} />}
                        </div>
                    </div>
                    {
                        description && <div className="marginBottom10px">
                            <p className="fontWeight600 textDimmerGray">
                                {description}
                            </p>
                        </div>
                    }
                    {!isAccordionOpen &&
                        <RouterLink className="colorBlue" to={`/${viewAll}`}
                            state={{ isQuickFilterEnable: quickFilterEnable }}
                            onClick={() => localStorage.setItem('quickFilter', JSON.stringify(quickFilterEnable))}
                            onContextMenu={() => localStorage.setItem('quickFilter', JSON.stringify(quickFilterEnable))}
                            data-testid="viewAll">{t("Dashboard.viewAll")}</RouterLink>}
                </div>
                :
                <>
                    <div className="flexJustifyBetween alignItemCenter marginBottom10px flexWrap"> <h3>{title}</h3>
                        <RouterLink className="colorBlue" to={`/${viewAll}`}
                            state={{ isQuickFilterEnable: quickFilterEnable }}
                            onClick={() => localStorage.setItem('quickFilter', JSON.stringify(quickFilterEnable))}
                            onContextMenu={() => localStorage.setItem('quickFilter', JSON.stringify(quickFilterEnable))}
                            data-testid="viewAll">{t("Dashboard.viewAll")}</RouterLink>
                    </div>
                    {
                        description && <div className="marginBottom10px">
                            <p className="fontWeight600 textDimmerGray">
                                {description}
                            </p>
                        </div>
                    }
                </>
            }
            {!isAccordionOpen && children}
        </div>
    )
}

export const CardItem = ({ children, addCardStyle = true }) => {
    return (
        <> {addCardStyle ? <div className={StyleSection.cardItemBox}>{children}</div> : <>{children}</>}</>
    )
}

export const ItemNameLink = ({ children, redirectTo = "" }) => {
    return (
        <div className="detailsHeading marginZero fontWeight600">
            <RouterLink
                to={`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${redirectTo}`}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                className={StyleSection.cardName}
                data-testid='patientNameLink'
            >
                {children}
            </RouterLink>
        </div>
    )
}

export const ItemLabel = ({ children, label, text }) => {
    return (
        <Typography
            className={`${StyleSection.scopedClassNameLine2} ${StyleSection.paddingBottomFive} ${!text ? StyleSection.itemLabelContainer : ''}`}
            sx={{ paddingBottom: "5px", fontSize: "var(--content-font-size-16px)" }}
        >
            {(text || children) && <span> {label}:&nbsp;</span>}
            {
                text ?
                    <span className={`${StyleSection.scopedClassColorText2}`}>
                        {text}
                    </span> :
                    <> {children} </>
            }
        </Typography>
    )
}

export const ItemNurseChip = ({ nurseList }) => {
    return (
        <Tooltip key={nurseList.nurse_name} title={nurseList.nurse_phone && (
            <span style={{ fontSize: 'var(--badges-font-size)' }}>
                {
                    nurseList.nurse_phone?.replace(regExPattern?.phoneNumberRegex, regExPattern?.phoneNumberFormat)
                }
            </span>
        )}>
            {
                nurseList.nurse_name && (
                    <div key={nurseList.nurse_name}>
                        <Chip label={nurseList.nurse_name} size="small" className={`${StyleSection.chipMargin}`} sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                                display: 'block',
                                whiteSpace: 'normal',
                            }, 
                            '@media (maxn-width:1024px)': {
                                maxWidth: "160px"
                              },
                        }} />
                    </div>
                )
            }
        </Tooltip>
    )
}

export const StatusChip = ({ status }) => {
    return (
        <Chip
            label={status}
            size="small"
            className={StyleSection.statusChip}
            sx={{ backgroundColor: StatusColor(status) }}
        />
    )
}