import React, { useEffect, useRef, useState } from 'react';
import Container from "@mui/material/Container";
import styles from './order-feedback.module.scss';
import withTitle from '../../../components/withTitle/withTitle';
import BasicBreadcrumbs from '../../../components/Breadcrumb/MuiBreadcrumb';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, DialogActions, FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import Ratings from './ratings';
import CustomRadioButton from './cutom-radio-button';
import { CustomNoteTextField } from '../../../components/MuiCustomComponent/MuiCustomComponent';
import { successToast } from '../../../components/ToastMessage/ToastMessage';
import { Controller, useController, useForm } from 'react-hook-form';
import { HTTPMethod, callApi } from '../../../services/HttpService/HttpService';
import { OrderFeedbackApi } from '../../../constants/ApiConstants';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { feedbackInputType, feedBackOrderTypeDisplayLabel } from '../../../common/enums';
import NoData from '../../../components/noData/noData';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const RenderInputField = ({ question, register, control, t, displayIndex, handleFormOnChange }) => {
  const { field, fieldState: { error } } = useController({ name: `question_${question.feedback_question_id}`, control});

  const DisplayLabel = () => {
    const inputLabel = question.input_label;

    if (inputLabel) {
      return (
        <p className='fontWeight600'>{`${displayIndex}. ${inputLabel}`}</p>
      );
    }
    return null;
  };

  switch (question.input_type) {
    case feedbackInputType.Bool:
      return (
        <DisplayLabel />
      );
    case feedbackInputType.Text:
      return (
        <div className='marginBottom10px'>
          <span>{question.input_label}</span>
          {(question?.input_limit_max_value && question?.input_limit_max_value < 128) ?//if limit max value is more than 128 we need to show free text
            <Controller
              control={control}
              name={`question_${question.feedback_question_id}`}
              {...register(`question_${question.feedback_question_id}`)}
              render={({ field }) => {
                return (
                  <TextField
                    defaultValue={question?.response}
                    value={field.value}
                    name={`question_${question.feedback_question_id}`}
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 128 }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                    variant="outlined"
                  />);
              }}
            /> :
            <CustomNoteTextField
              control={control}
              name={`question_${question.feedback_question_id}`}
              data-testid='custom-note-textfield'
              {...register(`question_${question.feedback_question_id}`)}
              multiline
              inputProps={{ maxLength: 500 }}
              aria-label={question?.input_placeholder}
              minRows={5}
              label={question?.input_placeholder}
              defaultValue={question?.response}
            />
          }
        </div>
      );
    case feedbackInputType.Rating:
      return (
        <div>
          <DisplayLabel />
          <Ratings
            id={`question_${question?.feedback_question_id}`}
            control={control}
            initialText={'Poor'}
            lastText={'Excellent'}
            value={question?.response}
          />
        </div>
      );
    case feedbackInputType.Dropdown:
      return (
        <div className='marginBottom10px'>
          <span>{question.input_label}</span>
          <FormControl fullWidth={true} size="small" >
            <Select
              id={`question_${question.feedback_question_id}`}
              IconComponent={KeyboardArrowDownIcon}
              value={field.value}
              onChange={(e) => {
                field.onChange(e); 
                handleFormOnChange(e); 
              }}
              className={styles.borderRadius8}
              defaultValue={question?.response}
              name={`question_${question.feedback_question_id}`}
            >
              {JSON.parse(question?.input_list_items).map((item, index) => {
                return (
                  <MenuItem key={item} value={item}>{JSON.parse(question?.input_list_items)[index]}</MenuItem>
                )
              })
              }
            </Select>
          </FormControl>
        </div>
      );
    case feedbackInputType.CheckBox:
      return (
        <div>
          <Checkbox
            className={`${styles.checkboxPadding} formCheckbox`}
            defaultChecked={question?.response == "true" }
            {...register(`question_${question?.feedback_question_id}`)}
           /> 
          <span className="checkBoxSelectText">{question?.input_label}</span>
        </div>
      );
    case feedbackInputType.RatingWithNA:
      return (
        <div>
          <DisplayLabel />
          <Ratings
            id={`question_${question?.feedback_question_id}`}
            control={control}
            initialText={'Poor'}
            lastText={'Excellent'}
            value={question?.response}
            naOption={true}
          />
        </div>
      );
    case feedbackInputType.YesNo:
      return (
        <div>
          <DisplayLabel />
          <CustomRadioButton
            id={`question_${question?.feedback_question_id}`}
            control={control}
            noButton={t("Common.no")}
            yesButton={t("Common.yes")}
            value={question?.response}
          />
        </div>
      );
    case feedbackInputType.Heading:
      return (
        <DisplayLabel />
      );
    default:
      return null;
  }
};

const OrderFeedback = () => {
  const { t } = useTranslation();
  const [orderFeedbackData, setOrderFeedbackData] = useState(null)
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState()
  const [feedbackGuid, setFeedbackGuid] = useState('');
  const [orderFeedbackQuestions, setOrderFeedbackQuestions] = useState(null);
  const parentQuestionCacheRef = useRef(new Map());

  const { register, handleSubmit, watch, control } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoginUser = useSelector(state => state.authState.isLoggedIn);
  const orderId = location.state?.orderFeedbackId || JSON.parse(localStorage.getItem("orderId"));
  const feedbackOrderId = orderId || sessionStorage.getItem('feedbackOrderId')

  useEffect(() => {
    const anonymousUrl = "/orders/order-feedback";
    if (feedbackGuid && location?.pathname == anonymousUrl) {
      const url = OrderFeedbackApi.orderFeedbackPage.replace("{order_feedback_id}", feedbackGuid);
      const questionsUrl = OrderFeedbackApi.orderFeedbackPageQuestions.replace("{order_feedback_id}", feedbackGuid);
      getOrderFeedback(url, questionsUrl);
    }
    if (feedbackOrderId && location?.pathname != anonymousUrl) {
      const url = OrderFeedbackApi.orderFeedbackPageAuth.replace("{order_id}", feedbackOrderId);
      const questionsUrl = OrderFeedbackApi.orderFeedbackPageAuthQuestions.replace("{order_id}", feedbackOrderId);
      getOrderFeedback(url, questionsUrl);
    }
  }, [feedbackGuid, feedbackOrderId])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const feedbackId = queryParams.get('Order-feedback-id')
    if (feedbackId) {
      setFeedbackGuid(feedbackId)
    }
    else if (orderId) {
      sessionStorage.setItem('feedbackOrderId', orderId)
    }
  }, [])

  const getOrderFeedback = async (url, questionsUrl) => {
    setOrderFeedbackData(null)
    const orderFeedbackResponse = await callApi(HTTPMethod.Get, url);

    if (orderFeedbackResponse?.status_code === 200 && orderFeedbackResponse?.data) {
      setOrderFeedbackData(orderFeedbackResponse?.data);
      setFormAlreadySubmitted(orderFeedbackResponse.data?.is_form_submitted);
      localStorage.setItem('orderId', orderFeedbackResponse.data?.order_id);
      const orderFeedbackResponseQuestions = await callApi(HTTPMethod.Get, questionsUrl);
      if (orderFeedbackResponseQuestions?.status_code === 200 && orderFeedbackResponseQuestions?.data) {
        setOrderFeedbackQuestions(orderFeedbackResponseQuestions?.data?.feedback_question)
      }
    }
    else {
      navigate('/feedback-expired', { replace: true })
    }
  }

  useEffect(() => {
    if (isLoginUser && feedbackGuid) {
      navigate('/orders/feedback-order' + '?Order-feedback-id=' + feedbackGuid);
    }
  }, [isLoginUser, feedbackGuid])

  const breadcrumbItems = [
    { label: "Orders", link: '/orders/order-details/' + localStorage.getItem('orderId') },
    { label: t("OrderFeedback.shareYourFeedbackForTheDME") }
  ];

  const onSubmit = async (data, partialDataPayload = false) => {
    const submitPayload = {
      order_feedback_response_model: {
        feedback_question_id: 0,
        response: null,
        is_completed: true,
      }
    }
    const feedbackPayload = partialDataPayload || submitPayload;

    if (!feedbackGuid) {
      const feedbackResponse = await callApi(HTTPMethod.Post, OrderFeedbackApi.orderFeedbackPageAuth.replace("{order_id}", orderFeedbackData?.order_id), feedbackPayload, "event-order-feedback");
      if (feedbackResponse && feedbackResponse?.status_code == 200) {
        if (!partialDataPayload) {
          successToast("Feedback submitted successfully.");
          localStorage.removeItem('orderId')
          navigate('/orders/order-details/' + orderFeedbackData.order_id);
          sessionStorage.removeItem('feedbackOrderId')
        }
      }
    }
    else {
      const feedbackResponse = await callApi(HTTPMethod.Post, OrderFeedbackApi.orderFeedbackPage.replace("{order_feedback_id}", feedbackGuid), feedbackPayload, "event-order-feedback");
      if (feedbackResponse && feedbackResponse?.status_code == 200) {
        if (!partialDataPayload) {
          successToast("Feedback submitted successfully.");
          localStorage.removeItem('orderId')
          if (isLoginUser) {
            navigate('/orders/order-details/' + orderFeedbackData.order_id);
            sessionStorage.removeItem('feedbackOrderId');
          }
          else {
            navigate('/support/feedback-thank-you');
          }
        }
      }
    }
  };

  const groupByOrderType = (equipmentArray) => {
    return equipmentArray?.reduce((acc, equipment) => {
      if (!acc[equipment.order_type]) {
        acc[equipment.order_type] = [];
      }
      acc[equipment.order_type].push(equipment);
      return acc;
    }, {});
  }

  const handleCancel = () => {
    navigate('/orders/order-details/' + orderFeedbackData?.order_id)
  }

  localStorage.setItem("drawerSelectedItem", "Orders");

  const OrderFeedbackHeader = () => {
    return (
      <>
        <Grid item container>
          <div>
            <p className={styles.orderType} style={{ marginLeft: '2px' }}>{t('SupportForm.orderID')}:</p>
          </div>
          <div>
            <p className={styles.orderName}>{orderFeedbackData?.order_id}</p>
          </div>
        </Grid>
        <table>
          {orderFeedbackData?.order_feedback_equipment && Object.entries(groupByOrderType(orderFeedbackData?.order_feedback_equipment)).map(([type, equipments]) => (
            <tr key={type}>
              <td className={styles.verticalAlignTop}>
                <div>
                  <p className={styles.orderType}>
                    {feedBackOrderTypeDisplayLabel[type]} {t("OrderFeedback.items")}:
                  </p>

                </div>
              </td>
              <td>
                {equipments.map((equipment) => (
                  <p className={styles.orderName} key={equipment.order_id}>
                    {equipment.order_equipment_name} ({t('OrderFeedback.qty')} : {equipment.equipment_quantity})
                  </p>
                ))}
              </td>
            </tr>
          ))}
        </table>
      </>
    )
  }


  const handleFormOnChange = (e) => {
    const partialPayload = {
      order_feedback_response_model: {
        feedback_question_id: parseInt(e.target.name?.split('_')[1]),
        response: e.target.type == 'checkbox' ? e.target.checked.toString() : e.target.value,
        is_completed: false
      }
    }
    onSubmit(null, partialPayload);
  }

  const findQuestionById = (id, questions = orderFeedbackQuestions) => {
    const stack = [...questions];    
    while (stack.length > 0) {
      const question = stack.pop();
      if (question.feedback_question_id === id) {
        return question;
      }
      if (question.dependent_questions && question.dependent_questions.length > 0) {
        stack.push(...question.dependent_questions);
      }
    }
    return null;
  }
  const renderDependentQuestions = (questions, currentIndex = 1) => {
    let updatedIndex = currentIndex;

    return questions.map((question) => {
      const dependentQuestionValue = watch(`question_${question?.dependent_feedback_question_id}`)?.toString();
      const feedbackQuestionValue = watch(`question_${question?.feedback_question_id}`);
      const dependentValue = question.dependent_feedback_question_value;

      let parentQuestion = null;
      let parentValue = dependentQuestionValue;
      const parentQuestionCache = parentQuestionCacheRef.current;
      
      if (question?.dependent_feedback_question_id && !dependentQuestionValue) {
        if (parentQuestionCache.has(question.dependent_feedback_question_id)) {
          parentQuestion = parentQuestionCache.get(question.dependent_feedback_question_id);
        } else {
          parentQuestion = findQuestionById(question?.dependent_feedback_question_id, orderFeedbackQuestions);
          parentQuestionCache.set(question.dependent_feedback_question_id, parentQuestion);
        }
        parentValue = (parentQuestion.input_type == feedbackInputType.CheckBox && !parentQuestion?.response) ? 'false' : parentQuestion?.response;
      }
      const shouldShowQuestion = question.dependent_feedback_question_id ? (dependentValue == feedbackQuestionValue || parentValue == dependentValue) : true;

      if (!shouldShowQuestion) {
        return null;
      }

      const displayIndex = updatedIndex++;

      return (
        <React.Fragment key={question.feedback_question_id}>
          <RenderInputField
            question={question}
            register={register}
            control={control}
            t={t}
            displayIndex={displayIndex}
            handleFormOnChange={handleFormOnChange}
          />
          {question.dependent_questions && renderDependentQuestions(question.dependent_questions, updatedIndex)}
        </React.Fragment>
      );
    });
  };

  return (
    <>{formAlreadySubmitted ? <>
      {isLoginUser && <BasicBreadcrumbs items={breadcrumbItems} />}
      <div data-testid="OrderFeedbackAlreadySubmitted" style={{ marginTop: '20px' }}>
        <NoData message={t("OrderFeedback.feedbackFormIsAlreadySubmitted")} />
      </div>
    </> :
      <div className={styles.OrderFeedback} data-testid="OrderFeedback">
        <Container className={`${styles.marginTop10} mainContainer`}>
          <BasicBreadcrumbs items={breadcrumbItems} />
          <div className={styles.marginTop20}>
            <OrderFeedbackHeader />
            <form onSubmit={handleSubmit((data) => onSubmit(data, false))} onChange={handleFormOnChange}>
              {(orderFeedbackQuestions && orderFeedbackQuestions?.length > 0) && renderDependentQuestions(orderFeedbackQuestions)}
              <DialogActions className={styles.actionButton}>
                {isLoginUser &&
                  <Button variant="contained"
                    className={styles.DialogButtonCancel}
                    onClick={handleCancel}
                    data-testid="cancelButton"
                  >
                    {t("Prescriptions.cancel")}
                  </Button>
                }
                <Button
                  variant="contained"
                  className={styles.dialogueButtonSubmit}
                  data-testid="submitButton"
                  type='submit'
                >
                  {t("OrderFeedback.submit")}
                </Button>
              </DialogActions>
            </form>
          </div>
        </Container>
      </div>
    }
    </>
  )
};

export default withTitle(OrderFeedback, "Order Feedback");
