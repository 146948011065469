import { Tab, Tabs, TextField, createTheme, styled, Tooltip, Badge, Autocomplete, TableCell } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Switch from '@mui/material/Switch';
import { tooltipClasses } from "@mui/material/Tooltip";
import { tableCellClasses } from '@mui/material/TableCell';


export const ToggleForms = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: '5px 5px',
    borderRadius: 8,
    justifyContent: 'space-around'
  }

});

export const ToggleForm = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  width: '100%',
  minHeight: 30,
  borderRadius: 8,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: '50%',
  },
  color: 'rgba(0, 0, 0, 0.85)',
  fontWeight: 600,
  '&:hover': {
    color: "black",
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: 'var(--color-base)',
    width: '100%',
    height: '43px',
    boxShadow: '0px 1px 5px #888888',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

export const ToggleTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '2px 3px',
    marginTop:'5px',
    borderRadius: 8
  },
  "& .MuiTab-root": {
    borderRadius: 8,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      height: "70%",
      width: 1,
      backgroundColor: "var(--color-darkgray-300)",
    },
    "&:first-of-type::before": {
      display: "none",
    },
  },
});

export const ToggleTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  width: 110,
  minHeight: 35,
  borderRadius: 8,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  color: 'var(--text-input-color)',
  fontWeight: "600",
  '&:hover': {
    color: "black",
    opacity: 1,
  },
  
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: 'var(--color-base)',
    height: '30px',
    fontWeight: theme.typography.fontWeightMedium,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      "&:hover": {
        boxShadow:
          "rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px",
      }
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

export const ToggleWideWithBarTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTabs-flexContainer": {
    border: "1px solid var(--color-darkgray-300)",
    display: "flex",
    width: "fit-content",
    padding: '2px 3px',
    borderRadius: 8,
  },
  "& .MuiTab-root": {
    borderRadius: 8,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      height: "70%",
      width: 1,
      backgroundColor: "var(--color-darkgray-300)",
    },
    "&:first-of-type::before": {
      display: "none",
    },
  },
});

export const ToggleWideWithBarTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  width: 140,
  minHeight: 35,
  borderRadius: 8,
  padding: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  color: 'var(--text-input-color)',
  fontWeight: "600",
  fontFamily: "var(--base-text)",
  "&:hover": {
    color: "black",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "white",
    height: '30px',
    backgroundColor: 'var(--color-base)',
    boxShadow: '0px 1px 2px #888888',
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const defaultDashboardTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            outline: "none",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
          "& .MuiIconButton-label": {
            transition: "none",
          },
          "& .MuiIconButton-rippleVisible": {
            display: "none",
          },
        },
      },
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "#000000", 
      },
    },
  },
  palette: {
    text: {
      primary: "#000000",
    },
    color: {
      primary: "#25A8E0",
    },
  },
  typography: {
    fontFamily: "Nunito Sans",
    color: "#000000",
    h6: {
      fontSize: "1.1rem",
    },
    "@media (max-width:480px)": {
      h6: {
        fontSize: "0.8rem",
      },
    },
    "@media (max-width:320px)": {
      h6: {
        fontSize: "0.6rem",
      },
    },
    "@media (max-width:1920px)": {
      h6: {
        fontSize: "0.6rem",
      },
    },
  },
});


export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, .125)",
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
  })
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid rgba(0, 0, 0, .125)",
  borderRadius: "5px !important",
  margin: "0px",
}));

export const DashboardAccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    display: "flex",
    justifyContent: "center",
  })
);

export const DashboardAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid rgba(0, 0, 0, .125)",
  borderRadius: "1px",
  margin: "0px",
}));



export const defaultTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          "&.Mui-focued": {
            borderColor: "rgb(25, 118, 210)"
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--text-input-color)',
          fontSize: '1rem'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiIconButton-rippleVisible": {
            display: "none",
          },
          "& .MuiIconButton-label": {
            transition: "none",
          },
        },
      },
    },
  },
  palette: {
    color: {
      primary: "var(--color-base)",
    },
    text: {
      primary: "#000",
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "#000",
      },
    },
  },
  typography: {
    color: "#000",
    fontFamily: "var(--base-text)",
    h6: {
      fontSize: "1.1rem",
    },
    "@media (max-width:320px)": {
      h6: {
        fontSize: "0.6rem",
      },
    },
    "@media (max-width:480px)": {
      h6: {
        fontSize: "0.8rem",
      },
    },
    "@media (max-width:1920px)": {
      h6: {
        fontSize: "0.6rem",
      },
    },
  },
});

export const OutlinedTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} InputLabelProps={{
    sx: {
      color: "var(--text-input-color)",
      fontFamily: "Nunito Sans",
      [`&.${inputLabelClasses.shrink}`]: {
        color: "var(--text-input-color)",
        fontFamily: "Nunito Sans"
      }
    }
  }} />
))(({ theme }) => ({
  '& .MuiFilledInput-root:after': {
    borderBottom: 'none'
  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 8,
    color: 'var(--text-input-color)',
    fontFamily: 'Nunito Sans',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'var(--text-input-color)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `none`,
      color: 'var(--text-input-color)'
    },
  },
}));

export const AntTabs = styled(Tabs)(({ theme, numberOfTabs }) => {
  const tabWidthPercentage = numberOfTabs ? `calc(100% / ${numberOfTabs})` : 'unset';

  return {
    marginTop: '20px',
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    "& .MuiTabs-flexContainer": {
      border: "1px solid var(--color-darkgray-300)",
      padding: "5px 4px",
      width: "auto",
      display: "flex",
      borderRadius: 4,
    },
    "& .MuiTab-root": {
      borderRadius: 4,
      position: "relative",
      width: tabWidthPercentage,
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        height: "70%",
        width: 1,
        backgroundColor: "var(--color-darkgray-300)",
      },
      "&:first-of-type::before": {
        display: "none",
      },
    },
    [theme.breakpoints.between('xs', 'md')]: {
      "& .MuiTabs-flexContainer": {
        width: 'fit-content',
      },
    },
    "& .MuiTabs-scrollButtons": {
      display: "none",
    },
    "& .MuiTabs-scrollButtonsDesktop": {
      display: "none",
    },
    maxWidth: 'unset',
  };
});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    width: 105,
    minHeight: 29,
    marginLeft:-1,
    borderRadius: 4,
    padding: 5,
    [theme.breakpoints.up("sm")]: {
      minWidth: "88px",
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: "var(--base-text)",
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "var(--color-base)",
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      "&:hover": {
        boxShadow:
          "rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px",
      }
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    maxWidth: 'unset',
  })
);
export const AntTabsForOrders = styled(Tabs)(({ theme, numberOfTabs }) => {
  const tabWidthPercentage = numberOfTabs ? `calc(100% / ${numberOfTabs})` : 'unset';

  return {
  marginTop: 'var(--gap-20px)',
  "& .MuiTabs-flexContainer": {
    borderRadius: 4,
    display: "flex",
    padding: "5px 4px",
    width: "100%",
    border: "1px solid var(--color-darkgray-300)",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTab-root": {
    position: "relative",
    borderRadius: 4,
    width: tabWidthPercentage,
    "&::before": {
      content: '""',
      position: "absolute",
      height: "70%",
      width: 1,
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      backgroundColor: "var(--color-darkgray-300)",
    },
    "&:first-of-type::before": {
      display: "none",
    },
  },
  "& .MuiButtonBase-root.MuiTab-root > .MuiTab-iconWrapper": {
    marginRight: 0,
  },
    [theme.breakpoints.down('sm')]: {
      "& .MuiTabs-flexContainer": {
        width: '180%',
      },
    },
    maxWidth: 'unset',
  }
});

export const AntTabForOrders = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minHeight: 29,
    marginLeft:-1,
    textTransform: "none",
    borderRadius: 4,
    padding: 5,
    width: 105,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "var(--base-text)",
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      opacity: 1,
      color: "black",
    },
    "&.Mui-selected": {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "var(--color-base)",
      color: "white",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      "&:hover": {
        boxShadow:
          "rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px",
      }
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    maxWidth: 'unset',
  })
);
export const AntTabsForRecurringOrders = styled(Tabs)(({ theme }) => ({
  marginTop: '20px',
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTabs-flexContainer": {
    width: "100%",
    padding: "5px 4px",
    display: "flex",
    border: "1px solid var(--color-darkgray-300)",
    borderRadius: 4,
  },
  "& .MuiTab-root": {
    position: "relative",
    borderRadius: 4,
    width:'25%',
    "&::before": {
      content: '""',
      position: "absolute",
      transform: "translateY(-50%)",
      left: 0,
      top: "50%",
      width: 1,
      height: "70%",
      backgroundColor: "var(--color-darkgray-300)",
    },
    "&:first-of-type::before": {
      display: "none",
    },
  },
  maxWidth: 'unset',
    [theme.breakpoints.down('sm')]: {
      "& .MuiTabs-flexContainer": {
        width: 'fit-content', 
      },
    },
    
}));

export const AntTabForRecurringOrders = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    width: 105,
    borderRadius: 4,
    minHeight: 29,
    padding: 5,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "var(--base-text)",
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "var(--color-base)",
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      "&:hover": {
        boxShadow:
          "rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px",
      } 
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    maxWidth: 'unset',
  })
);
export const AntTabsNewOrder = styled(Tabs)(({ theme }) => ({
  marginTop: '20px',
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTabs-flexContainer": {
    border: "1px solid var(--color-darkgray-300)",
    display: "flex",
    width: "100%",
    padding: "5px 4px",
    borderRadius: 4,
  },
  "& .MuiTab-root": {
    borderRadius: 4,
    position: "relative",
    width:'20%',
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      height: "70%",
      width: 1,
      backgroundColor: "var(--color-darkgray-300)",
    },
    "&:first-of-type::before": {
      display: "none",
    },
  },
    [theme.breakpoints.down('sm')]: {
      "& .MuiTabs-flexContainer": {
        width: 'fit-content',
      },
    },
    maxWidth: 'unset',
}));

export const AntTabNewOrder = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    width: 105,
    minHeight: 29,
    marginLeft:-1,
    borderRadius: 4,
    padding: 5,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: "var(--base-text)",
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "var(--color-base)",
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      "&:hover": {
        boxShadow:
          "rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px",
      } 
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    maxWidth: 'unset',
  })
);
export const CustomBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 10,
    fontSize: "11px",
    borderRadius: "50%",
    padding: "10px 6px 10px 5px",
    backgroundColor: "var(--color-base)",
  },
}));


export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 19,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(14px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "var(--bg-white)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--color-base)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 10,
    margin: '0 0 0 1px',
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));


export const arrowIconStyle = {
  position: "absolute",
  top: "50%",
  right: "12px",
  transform: "translateY(-50%)",
  color: "rgba(151, 151, 151, 0.5)",
};



export const headingStyles = {
  color: "#000",
  textAlign: "center",
  fontSize: "1.5rem",
  fontFamily: "Nunito Sans",
  fontWeight: "700",
  lineHeight: "1.2",
};

export const bodyStyles = {
  fontSize: "1rem",
  fontFamily: "Nunito Sans",
  color: "#000",
  lineHeight: " 1.2",
};

export const body2Styles = {
  fontSize: "1rem",
  fontFamily: "Nunito Sans",
  color: "#000",
  lineHeight: " 1.2",
  visibility: "hidden",
};

export const EMRTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props}  classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#545454',
  },
}));

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  '.MuiInputBase-root ': { padding: '0px', },
  '.MuiOutlinedInput-root ': { borderRadius: "8px", }
}));

export const CustomNoteTextField = styled(TextField)({
  borderRadius: '8px !important',
  width:'100%'
});

export const CustomEmailTextfield = styled(TextField)(() => ({
  '.MuiInputBase-root ': { padding: '0px'},
  '.MuiOutlinedInput-root ': { borderRadius: "8px", },
}));

export const headerStyleForPickupEquipment = {
  fontSize: "16px",
  fontWeight: 600,
  padding: '16px 16px 5px 16px'
}

export const headerStyleForQuantity = {
  textAlign: 'right',
  fontSize: "16px",
  fontWeight: 600,
  padding: '0px 16px 5px 16px'
}

export const cellStyle = {
  lineHeight: '0.5',
  padding: '16px'
}
export const cellStyleQuantity = {
  lineHeight: '0.5',
  textAlign: 'right',
  padding: '16px'
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: "left",
    lineHeight: '1 !important'

  },
}));

export const headerStyleForEquipment = {
  fontSize: "16px",
  fontWeight: 600,
  padding: '0px 16px 5px 16px'
}

export const StyledTableCellPickup = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: "left",

  },
}));